import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Menu,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import FolderImage from "images/folderIcon.svg";
import DotsImage from "images/threeDots.svg";
import { useHistory } from "react-router-dom";
import { formatDate } from "utils";
import { deleteFolder, UpdateFolder } from "./FileApi";
import { isSebi } from "Routes/Routes";
import EditPermissions from "./EditPermissions";

export default function FolderView({
  folder,
  disputeId,
  classes,
  reload,
  profile,
  allProfiles,
  parent_folder,
  selectedFolders,
  handleChangeOnFolderCheckbox,
  unSelectAll,
}) {
  const history = useHistory();
  const [anchorElLocal, setAnchorElLocal] = useState(null);
  const handleClick = (e) => {
    e.stopPropagation();
    setAnchorElLocal(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorElLocal(null);
  };

  const [buttonsEnabled, setButtonsEnabled] = useState(true);
  useEffect(() => {
    if (
      folder &&
      folder.uploaded_by.id != profile.user.id &&
      folder.edit_permission.indexOf(profile.user.id) == -1
    ) {
      setButtonsEnabled(false);
    }
    if (
      profile.profile_type == "sub_admin_tiac_rv" ||
      profile.profile_type == "case_manager" ||
      profile.profile_type == "admin"
    ) {
      setButtonsEnabled(true);
    }
  }, []);

  const [openmodalUploadFolder, setOpenmodalUploadFolder] = useState(false);
  const handleOpenUploadFolderModal = () => {
    if (
      profile.user.id == folder.uploaded_by.id ||
      profile?.profile_type === "case_manager" ||
      profile?.profile_type === "sub_admin_tiac_rv"
    )
      setOpenmodalUploadFolder(true);
    else alert("only owner can edit permissions");
  };
  const handleCloseUploadFolderModalAndRefresh = () => {
    reload();
    setOpenmodalUploadFolder(false);
    setAnchorElLocal(null);
  };
  const handleCloseUploadFolderModal = () => {
    setOpenmodalUploadFolder(false);
    setAnchorElLocal(null);
  };

  const [rename, setRename] = useState(false);
  const [name, setName] = useState(folder.name);
  const handleFolderNameChange = (e) => {
    setName(e.target.value);
  };
  const inputRef = useRef(null);
  const handleOnBlur = (e) => {
    setRename(false);
    if (folder.name != name) {
      UpdateFolder(folder.id, {
        name: name,
      }).then(() => {
        reload();
      });
    }
  };

  const handleRename = () => {
    if (buttonsEnabled) {
      handleClose();
      setRename(true);
      setTimeout(() => {
        inputRef.current.focus();
        inputRef.current.select();
      }, 100);
    } else {
      alert("You don't have edit permissions.");
    }
  };

  const handleDelete = () => {
    if (buttonsEnabled) {
      deleteFolder(folder.id).then(() => {
        unSelectAll();
        reload();
      });
    } else {
      alert("You don't have delete permissions.");
    }
  };

  const handleOpenFolder = (id) => {
    history.push(`/disputes/${disputeId}/files/${id}`);
  };

  const isSelected = selectedFolders?.includes(`${folder.id}`);

  return (
    <>
      <Grid
        container
        item
        className={classes.filesHover}
        spacing={1}
        onDoubleClick={(e) => {
          handleOpenFolder(folder.id);
        }}
        justify="space-between"
      >
        <Grid item sm={profile.profile_type === "case_manager" ? 6 : 8} xs={8}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              id={folder.id}
              color="primary"
              className={
                isSelected ? classes.visibility : classes.visibilityHidden
              }
              style={{ padding: "5px", height: "fit-content" }}
              checked={isSelected}
              onChange={handleChangeOnFolderCheckbox}
            />
            <Typography className={classes.tableRowContent}>
              <span style={{ verticalAlign: "middle" }}>
                <img src={FolderImage} alt="folder" />
              </span>
              <span style={{ marginLeft: "1rem" }}>
                {!rename ? (
                  <span className={classes.fileFolderName}>{name}</span>
                ) : (
                  <input
                    ref={inputRef}
                    value={name}
                    style={{ width: "70%" }}
                    onChange={handleFolderNameChange}
                    onBlur={handleOnBlur}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleOnBlur();
                      }
                    }}
                  />
                )}
              </span>
            </Typography>
          </div>
        </Grid>
        <Grid item sm={2} xs={4}>
          <Typography className={classes.tableRowContent}>
            {folder.uploaded_by.first_name} {folder.uploaded_by.last_name}
          </Typography>
        </Grid>
        {profile.profile_type === "case_manager" && (
          <Grid item sm={2} xs={4}>
            <Typography className={classes.tableRowContent}>
              {formatDate(folder.modified)}
            </Typography>
          </Grid>
        )}
        <Grid item sm={2} xs={4}>
          <Typography className={classes.tableRowContent}>
            {formatDate(folder.created)}
            <IconButton
              onClick={handleClick}
              aria-controls={`three-dots-menu${folder.id}`}
              aria-haspopup="true"
              style={{ padding: 0, float: "right" }}
            >
              <img src={DotsImage} alt="options" />
            </IconButton>
          </Typography>
        </Grid>
      </Grid>
      <Menu
        id={`three-dots-menu${folder.id}`}
        anchorEl={anchorElLocal}
        keepMounted
        open={Boolean(anchorElLocal)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        style={{ padding: 0 }}
      >
        <Box className={classes.menuwidth}>
          <Box>
            <Typography
              className={classes.filesandfolder}
              onClick={handleRename}
            >
              Rename
            </Typography>
            {((isSebi && profile.profile_type === "admin") || !isSebi) && (
              <Typography
                className={classes.filesandfolder}
                onClick={handleDelete}
              >
                Delete
              </Typography>
            )}
            {(profile.user.id == folder.uploaded_by.id ||
              profile?.profile_type === "case_manager" ||
              profile?.profile_type === "sub_admin_tiac_rv") && (
              <Typography
                className={classes.filesandfolder}
                onClick={handleOpenUploadFolderModal}
              >
                Edit permissions
              </Typography>
            )}
          </Box>
        </Box>
      </Menu>
      {openmodalUploadFolder && (
        <EditPermissions
          openmodal={openmodalUploadFolder}
          handleCloseModal={handleCloseUploadFolderModal}
          handleCloseModalRefresh={handleCloseUploadFolderModalAndRefresh}
          files={[]}
          folders={[folder]}
          allProfiles={allProfiles}
          parent_folder={parent_folder}
          disputeId={disputeId}
          profile={profile}
          viewPermission={folder.view_permission}
          editPermission={folder.edit_permission}
        />
      )}
    </>
  );
}
