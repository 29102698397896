import { Box, Button, Typography, makeStyles } from "@material-ui/core";
import React from "react";

import {
  isDifc,
  isDigipub,
  isHotStar,
  isNSE,
  isRv,
  isViacom,
  isSebi,
  isStar,
  isWebnyay,
  isMuthoot,
} from "Routes/Routes";
import { useDispatch } from "react-redux";
import { deleteMeeting } from "slices/appointmentSlice";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: "3rem auto",
    minHeight: "10rem",
    width: "50%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    outline: "none",
    borderRadius: "0.4rem",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      width: "60%",
      padding: theme.spacing(3, 2, 3),
    },
  },

  greyColor: {
    color: theme.palette.common.grey1,
  },
  hoverButton: {
    background: "#3F51B5",
    color: "#FFFFFF",
    border: "1px solid transparent",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      color: "#3F51B5",
      border: "1px solid #3F51B5",
    },
  },
}));

export default function EventDisplay({
  eventDetails,
  handleEventDetailsClose,
  sources,
  profile,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    title,
    extendedProps: {
      appointmentId,
      dispute,
      meetingId,
      appointmentName,
      isPastEvent,
      ext_link,
      tiac_custom_dispute_id,
      group,
    },
  } = eventDetails.event["_def"];

  const onCancel = () => {
    dispatch(deleteMeeting(meetingId, dispute)).then(() => {
      handleEventDetailsClose();
    });
  };
  const [by, setBy] = React.useState();

  React.useEffect(() => {
    if (sources[0]["events"].length > 0) {
      sources[0]["events"].map((e) => {
        if (e.meeting.id == meetingId) {
          if (e.meeting.vc_type == "zoom") {
            setBy("Zoom VC");
          } else {
            setBy("In-built VC");
          }
        }
      });
    }
  }, []);

  let data;
  if (isHotStar || isStar || isMuthoot) {
    data = [
      {
        title:
          isWebnyay || isViacom || isDigipub
            ? "Grievance"
            : isRv
            ? "Case"
            : "Dispute",
        value: dispute,
      },
      {
        title: "Meeting link",
        value: ext_link,
      },
      {
        title: "Meeting ID",
        value: meetingId,
      },
    ];
  } else if (isRv || isWebnyay) {
    data = [
      {
        title:
          isWebnyay || isViacom || isDigipub
            ? "Grievance"
            : isRv || isNSE
            ? "Case"
            : "Dispute",
        value: tiac_custom_dispute_id ? tiac_custom_dispute_id : dispute,
      },
      {
        title: "Event title",
        value: title,
      },
      {
        title: isWebnyay || isRv ? "Hearing Details " : " ",
        value: by,
      },
      {
        title: "Meeting ID",
        value: meetingId,
      },
      {
        title: "Claimant vs Respondent",
        value: appointmentName,
      },
    ];
  } else {
    data = [
      {
        title:
          isWebnyay || isViacom || isDigipub
            ? "Grievance"
            : isRv || isNSE
            ? "Case"
            : "Dispute",
        value: tiac_custom_dispute_id ? tiac_custom_dispute_id : dispute,
      },
      {
        title: "Event title",
        value: title,
      },
      {
        title: "Meeting ID",
        value: meetingId,
      },
      {
        title: "Claimant vs Respondent",
        value: appointmentName,
      },
    ];
  }

  const isScheduleButtonDisabled =
    (isSebi &&
      profile.profile_type !== "mediator" &&
      profile.profile_type !== "arbitrator_rv" &&
      profile.profile_type !== "case_manager") ||
    (isDifc &&
      profile.profile_type !== "case_manager" &&
      profile.profile_type !== "sub_admin_tiac_rv");

  return (
    <div className={classes.paper}>
      <Typography variant="h5">Event Details</Typography>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="60%"
        m="0 auto"
      >
        {data.map((singleItem) => {
          return (
            <Info
              title={singleItem.title}
              value={singleItem.value}
              key={singleItem.title}
            />
          );
        })}
        <Button
          disabled={isPastEvent || isScheduleButtonDisabled}
          color="primary"
          variant="contained"
          onClick={onCancel}
          className={(isHotStar || isStar || isMuthoot) ? classes.hoverButton : null}
        >
          {isPastEvent ? "Completed" : "Cancel Meeting"}
        </Button>
      </Box>
    </div>
  );
}

const Info = ({ title, value }) => {
  return (
    <Box
      width="100%"
      m="1rem 0"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box>
        <Typography style={{ color: "#000" }} variant="h6">
          {title}
        </Typography>
      </Box>
      <Box height="0.3rem"></Box>
      <Box>
        <Typography variant="h6">{value}</Typography>
      </Box>
    </Box>
  );
};
