import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getCountries } from "slices/profileSlice";

const useStyles = makeStyles((theme) => ({
  boldtext: {
    fontWeight: "bold",
    fontSize: "1.2rem",
    lineHeight: "23px",
    letterSpacing: "0.6px",
    color: "#454545",
  },
  text: {
    fontWeight: "500",
    fontSize: "1.2rem",
    lineHeight: "23px",
    letterSpacing: "0.4px",
    color: "#454545",
  },
}));

export default function EditProfileRow({ profileData }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [haveProfile, setHaveProfile] = useState(
    profileData.profile ? true : false
  );

  const [id, setid] = useState(
    haveProfile ? profileData.profile.id : profileData.id
  );
  const [firstname, setFirstname] = useState(profileData.user.first_name);
  const [lastname, setLastname] = useState(profileData.user.last_name);
  const [phone, setPhone] = useState(
    haveProfile ? profileData.profile.phone_number : profileData.phone_number
  );
  const [email, setEmail] = useState(profileData.user.email);
  const [address, setAddress] = useState(
    haveProfile ? profileData.profile.address : profileData.address
  );
  const [city, setCity] = useState(
    haveProfile ? profileData.profile.city : profileData.city
  );
  const [region, setRegion] = useState(
    haveProfile ? profileData.profile.region : profileData.region
  );
  const [code, setCode] = useState(
    haveProfile ? profileData.profile.postal_code : profileData.postal_code
  );
  const [profileType, setprofileType] = useState(
    haveProfile ? profileData.profile.profile_type : profileData.profile_type
  );
  const [country, setCountry] = useState(
    haveProfile ? profileData.profile.country : profileData.country
  );
  // const [country, setCountry] = useState("-");
  const [allCountries, setallCountries] = useState([]);

  React.useEffect(() => {
    getCountries().then((res) => {
      let result = [...res];
      result.sort(function (a, b) {
        var nameA = a.name.toUpperCase();
        var nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setallCountries(result);
    });
  }, []);

  allCountries.map((item) => {
    if (item.id == country) {
      setCountry(item.name);
    }
  });

  return (
    <>
      <Grid container justify="center">
        <Grid item md={9} sm={12} xs={12}>
          <Grid container justify="space-between">
            <Grid item>
              <Typography className={classes.boldtext}>
                {" "}
                First Name :{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.text}>{firstname}</Typography>
            </Grid>
          </Grid>
          <Box height="0.5rem"></Box>
          <Grid container justify="space-between">
            <Grid item>
              <Typography className={classes.boldtext}>
                {" "}
                Last Name :{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.text}>{lastname}</Typography>
            </Grid>
          </Grid>
          <Box height="0.5rem"></Box>
          <Grid container justify="space-between">
            <Grid item>
              <Typography className={classes.boldtext}>
                {" "}
                Phone Number :{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.text}>{phone}</Typography>
            </Grid>
          </Grid>
          <Box height="0.5rem"></Box>
          <Grid container justify="space-between">
            <Grid item>
              <Typography className={classes.boldtext}> Email : </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.text}>{email}</Typography>
            </Grid>
          </Grid>
          <Box height="0.5rem"></Box>
          <Grid container justify="space-between">
            <Grid item>
              <Typography className={classes.boldtext}>
                {" "}
                Apartment and Street :{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.text}>{address}</Typography>
            </Grid>
          </Grid>
          <Box height="0.5rem"></Box>
          <Grid container justify="space-between">
            <Grid item>
              <Typography className={classes.boldtext}> City : </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.text}>{city}</Typography>
            </Grid>
          </Grid>
          <Box height="0.5rem"></Box>
          <Grid container justify="space-between">
            <Grid item>
              <Typography className={classes.boldtext}> Region : </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.text}>{region}</Typography>
            </Grid>
          </Grid>
          <Box height="0.5rem"></Box>
          <Grid container justify="space-between">
            <Grid item>
              <Typography className={classes.boldtext}>
                {" "}
                Postal_Code :{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.text}>{code}</Typography>
            </Grid>
          </Grid>
          <Box height="0.5rem"></Box>
          <Grid container justify="space-between">
            <Grid item>
              <Typography className={classes.boldtext}> Country : </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.text}>{country}</Typography>
            </Grid>
          </Grid>
          <Box height="0.5rem"></Box>
          <Grid container justify="space-between">
            <Grid item>
              <Typography className={classes.boldtext}>
                {" "}
                Profile Type :{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.text}>
                {profileType == "general_rv"
                  ? "Party"
                  : profileType == "arbitrator_rv"
                  ? "Arbitrator"
                  : profileType == "lawyer_rv"
                  ? "Counsel"
                  : "Case Manager"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
