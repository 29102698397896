import { createSlice } from "@reduxjs/toolkit";
import instance, { instanceDPCGC, instanceHotstar } from "api/globalaxios";
import { auth } from "app/firebase";
import { getProfile } from "slices/profileSlice";
import { getUser } from "slices/userSlice";
import { getChatAuthToken, setChatAuthToken } from "utils/localStorage";
import { getRealhost, gethost } from "slices/disputeSlice";
import { isArb, isArbitrationConference, isDifc, isTiac, isSebi, isKilumaya } from "Routes/Routes";
import { getTimeZone } from "./date";

export const initialState = {
  loading: false,
  hasErrors: false,
  token: isLoggedIn(),
  chatToken: returnChatCredentials(1),
  chatId: returnChatCredentials(2),
  errors: {},
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setLoginProgress: (state) => {
      state.loading = true;
    },
    setTokenSucessfull: (state, { payload }) => {
      login(payload);
      state.token = payload.token;
      state.chatToken = payload.chat_token;
      state.chatId = payload.chat_user_id;
      state.loading = false;
      state.hasErrors = false;
    },
    setChatToken: (state, { payload }) => {
      state.chatToken = payload.chatToken;
      state.chatId = payload.userId;
      state.loading = false;
      state.hasErrors = false;
    },
    setLoginError: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errors = payload;
    },
    setLogOut: (state) => {
      state.loading = false;
      state.token = "";
      state.chatToken = "";
      state.chatId = "";
      state.hasErrors = false;
    },
  },
});

export const {
  setLoginProgress,
  setTokenSucessfull,
  setLoginError,
  setLogOut,
  setChatToken,
} = loginSlice.actions;
export default loginSlice.reducer;

export function setTokenAsync(username, password) {
  return async (dispatch) => {
    dispatch(setLoginProgress());
    const timezone = getTimeZone();
    try {
      let response = await instance({
        method: "post",
        url: "/token-auth/?host=" + getRealhost(),
        data: {
          email: username,
          password,
          timezone
        },
      });

      dispatch(setTokenSucessfull(response.data));
      dispatch(getUser());
      dispatch(getProfile());
    } catch (error) {
      console.log(error);
      if (error.request.status === 400) {
        dispatch(setLoginError(JSON.parse(error.request.response)));
      }
      throw error;
    }
  };
}

export function setTokenAsyncHotstarAdmin(username, password) {
  return async (dispatch) => {
    try {
      let response = await instanceHotstar({
        method: "post",
        url: "/token-auth/?host=" + getRealhost(),
        data: {
          email: username,
          password: password,
        },
      });
      console.log("hotstar", response.data);
      localStorage.setItem("hotstar-admin-token", response.data.token);
      localStorage.setItem("hotstar-admin-chatToken", response.data.chat_token);
      localStorage.setItem("hotstar-admin-userId", response.data.chat_user_id);
    } catch (error) {
      console.log(error);
      if (error.request.status === 400) {
        dispatch(setLoginError(JSON.parse(error.request.response)));
      }
      throw error;
    }
  };
}
export function setTokenAsyncDpcgcAdmin(username, password) {
  return async (dispatch) => {
    try {
      let response = await instanceDPCGC({
        method: "post",
        url: "/token-auth/?host=" + getRealhost(),
        data: {
          email: username,
          password: password,
        },
      });
      console.log("dpcgc", response.data);
      localStorage.setItem("dpcgc-admin-token", response.data.token);
      localStorage.setItem("dpcgc-admin-chatToken", response.data.chat_token);
      localStorage.setItem("dpcgc-admin-userId", response.data.chat_user_id);
    } catch (error) {
      console.log(error);
      if (error.request.status === 400) {
        dispatch(setLoginError(JSON.parse(error.request.response)));
      }
      throw error;
    }
  };
}

export function sendOtpAsync(
  email,
  firstName,
  phoneNumber,
  disableMobile = false
) {
  return async (dispatch) => {
    try {
      phoneNumber = "" + phoneNumber;
      let response = await instance({
        method: "post",
        url:
          isTiac || isArb || isKilumaya || isDifc || isArbitrationConference || isSebi
            ? "/api/get-otp-email/?host=" +
              gethost() +
              "tiac" +
              "&disablemobile=" +
              disableMobile
            : "/api/get-otp-email/?host=" +
              gethost() +
              "&disablemobile=" +
              disableMobile,
        data: {
          email: email,
          name: firstName,
          mobile: phoneNumber,
        },
      });
      console.log("otp message", response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      if (error.request.status === 400) {
        dispatch(setLoginError(JSON.parse(error.request.response)));
      }
      return JSON.parse(error.request.response);
    }
  };
}

export function logout() {
  localStorage.removeItem("token");
  localStorage.removeItem("chatToken");
  localStorage.removeItem("userId");
  sessionStorage.removeItem("token");
  socialLogout();
  return {
    type: "USER_LOGGED_OUT",
  };
}

export function socialLogout() {
  return auth.signOut();
}

function login(payload) {
  localStorage.setItem("token", payload.token);
  sessionStorage.setItem("token", payload.token);
  setChatAuthToken(payload.chat_token, payload.chat_user_id);
}

export function isLoggedIn() {
  let token = "";
  token = localStorage.getItem("token");
  if (!token) {
    token = sessionStorage.getItem("token");
  }
  if (token) {
    return token;
  } else {
    logout();
    return "";
  }
}
export function isLoggedInHotstarAdmin() {
  const token = localStorage.getItem("hotstar-admin-token");
  if (token) {
    return token;
  } else {
    return "";
  }
}
export function isLoggedInDPCGCAdmin() {
  const token = localStorage.getItem("dpcgc-admin-token");
  if (token) {
    return token;
  } else {
    return "";
  }
}

export function returnChatCredentials(type) {
  const { token, userId } = getChatAuthToken();
  return type === 1 ? token : userId;
}

export function changePassword(currentPassword, newPassword) {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: "patch",
        url: "/api/changepassword/",
        data: {
          currentPassword,
          newPassword,
        },
      });

      dispatch(getUser());
      dispatch(getProfile());
    } catch (error) {
      console.log(error);
      if (error.request.status === 400) {
        throw error.request.response;
        // dispatch(setLoginError(JSON.parse(error.request.response)));
      }
    }
  };
}

export function socialAuth({ idToken }) {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: "post",
        url: "/api/social" + "?host=" + gethost(),
        data: { idToken },
      });

      console.log(response.data);
      dispatch(setTokenSucessfull(response.data));
      dispatch(getUser());
      dispatch(getProfile());
    } catch (error) {
      console.log(error);
      if (error.request.status === 400) {
        throw error.request.response;
        // dispatch(setLoginError(JSON.parse(error.request.response)));
      }
    }
  };
}
export async function verifyResetToken({ token }) {
  try {
    let response = await instance({
      method: "post",
      url: `/api/password_reset/validate_token`,
      params: { token },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.request.status === 400) {
      throw error.request.response;
      // dispatch(setLoginError(JSON.parse(error.request.response)));
    }
  }
}

export async function getLinkedinCustomToken({ code, state }) {
  try {
    let response = await instance({
      method: "get",
      url: `/api/linkedin-callback` + "?host=" + gethost(),
      params: { code, state },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.request.status === 400) {
      throw error.request.response;
      // dispatch(setLoginError(JSON.parse(error.request.response)));
    }
  }
}

export async function resetPassword({
  email,
  token,
  password,
  setPasswordError,
}) {
  try {
    let response = await instance({
      method: "post",
      url: `/api/reset_password/change`,
      data: { token, password, email },
    });
    console.log(" responseeeee", response);
    console.log(" response dataaaa", response.data);
    return response.data;
  } catch (error) {
    console.log(error);

    if (error.request.status === 400) {
      console.log("errorrrrr", error.request.response);
      let str = error.request.response;
      str = JSON.parse(str);
      console.log(str.password[0]);
      setPasswordError(str.password[0]);

      throw error.request.response;

      // dispatch(setLoginError(JSON.parse(error.request.response)));
    }
  }
}

export function forgotPassword(email) {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: "post",
        url: "/api/password_reset/?host=" + getRealhost(),
        data: {
          email,
        },
      });

      return response.data;

      // dispatch(getUser());
      // dispatch(getProfile());
    } catch (error) {
      console.log(error);
      if (error.request.status === 400) {
        throw error.request.response;
        // dispatch(setLoginError(JSON.parse(error.request.response)));
      }
    }
  };
}
