import React from "react";
import { Box, CircularProgress } from "@material-ui/core";
import {
  isArb,
  isArbitrationConference,
  isDifc,
  isTiac,
  isSebi,
  isKilumaya,
} from "Routes/Routes";

export default function CircularCenteredLoader() {
  return (
    <Box display="flex" justifyContent="center" margin="3rem 0 0 0">
      <CircularProgress
        disableShrink
        style={
          isTiac ||
          isArb ||
          isKilumaya ||
          isDifc ||
          isArbitrationConference ||
          isSebi
            ? { color: "#2F80ED" }
            : {}
        }
      />
    </Box>
  );
}
