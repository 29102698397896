import {
  Box,
  Button,
  Hidden,
  List,
  ListItem,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { getWindowDimension } from "utils/responsiveUtils";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CreateDispute from "./CreateDispute";
import Overview from "./Overview";
import CreateAccount from "./CreateAccount";
import UserDetails from "./UserDetails";
import Drive from "./Drive";
import Calender from "./Calender";
import CaseManager from "./CaseManagerSideBar";
import Arbitrator from "./ADRP";
import instance from "api/globalaxios";
import { removeSessionStorage } from "features/tiac_header_waiting/TiacAppHeader";
import { isSebi, isTiac, isArb, isKilumaya } from "Routes/Routes";
import SebiFinances from "Admin/Sebi/Finances";
import CreateMeeting from "Admin/SuperAdmin/CreateMeeting";
import InvoiceList from "Admin/Common/InvoiceList";
import Mediator from "./Mediator";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    minHeight: "95vh",
    // height: "100%",
    borderRadius: "1rem",
    // flexDirection: (props) => (props.smallScreen ? "column" : "row"),
  },
  sidebar: {
    background: "#2C2C2C",
    width: "23rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  sidebarItem: {
    //#A4A6B3;
    fontSize: "16px",
    padding: "0.5rem 4rem",
    color: "#A4A6B3",
    letterSpacing: "0.2px",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
      padding: "0.5rem 2.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      padding: "0.4rem 0 0.4rem 3rem",
    },
  },
  selected: {
    backgroundColor: "rgba(159, 162, 180, 0.08)",
    // opacity: 0.08,
    // color:"#FFFFFF",
    borderLeft: "2px solid #FFFFFF;",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "0",
    overflow: "hidden",
    width: "100%",
    textAlign: "center",
  },
  body2: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "0",
    overflow: "hidden",
    width: "100%",
    textAlign: "center",
  },
}));

export default function RVAdminTiac({ profile }) {
  const classes = useStyles();
  let history = useHistory();

  let ls = sessionStorage.getItem("tiac_sidebar_step");
  let mb = sessionStorage.getItem("tiac_sidebar_mobilestep");

  const [step, setStep] = useState(mb ? mb : 0);

  const [selected, setSelected] = useState(
    ls ? ls : getWindowDimension().width < 960 ? 0 : 1
  );

  const [registeredDisputesList, setregisteredDisputesList] = useState({});

  async function getDisputes() {
    return await instance.get("/tiac_admin_disputes/");
  }

  useEffect(() => {
    getDisputes().then((res) => {
      if (isSebi) {
        setregisteredDisputesList([
          ...res.data["Conciliation"],
          ...res.data["Arbitration"],
          ...res.data["closed"],
        ]);
      } else {
        setregisteredDisputesList(res.data["Requests Registered"]);
      }
    });
  }, []);

  const RenderOptions = () => {
    if (selected == 1 || selected == 0) {
      return <Overview profile={profile} />;
    }
    if (selected == 2) {
      return <CreateAccount profile={profile} />;
    }
    if (selected == 3) {
      return <UserDetails profile={profile} />;
    }
    if (selected == 4) {
      return (
        <SebiFinances
          profile={profile}
          registeredDisputesList={registeredDisputesList}
        />
      );
    }
    if (selected == 5) {
      return <CaseManager profile={profile} />;
    }
    if (selected == 6) {
      return <Arbitrator profile={profile} />;
    }
    if (selected == 13) {
      return <Mediator profile={profile} />;
    }
    if (selected == 7) {
      return <CreateDispute />;
    }
    if (selected == 8) {
      return <Drive profile={profile} />;
    }
    if (selected == 9) {
      return <Calender profile={profile} />;
    }
    if (selected == 10) {
      return <CreateMeeting profile={profile} />;
    }
    if (selected == 11) {
      return <InvoiceList profile={profile} />;
    }
  };

  const Sidebar = () => {
    return (
      <Box className={classes.sidebar}>
        <Box height="1rem"></Box>
        <Typography
          style={{
            padding: "15px 20px 30px 20px",
            fontSize: "18px",
            color: "rgba(238, 238, 238, 0.7)",
            fontWeight: "bold",
          }}
        >
          Dashboard
        </Typography>

        <List style={{ paddingTop: "0" }}>
          {selected == 1 ? (
            <ListItem
              className={classes.selected}
              button
              onClick={() => {
                setSelected(1);
                setStep(1);
                removeSessionStorage();
                history.push("/");
              }}
            >
              <Typography
                className={classes.sidebarItem}
                style={{ color: "#FFFFFF" }}
              >
                Overview
              </Typography>
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={() => {
                setSelected(1);
                setStep(1);
                // history.push('/')
                removeSessionStorage();
                sessionStorage.setItem("tiac_sidebar_mobilestep", 1);
                sessionStorage.setItem("tiac_sidebar_step", 1);
              }}
            >
              <Typography className={classes.sidebarItem}>Overview</Typography>
            </ListItem>
          )}
          {selected == 2 ? (
            <ListItem
              className={classes.selected}
              button
              onClick={() => {
                setSelected(2);
                setStep(1);
              }}
            >
              <Typography
                className={classes.sidebarItem}
                style={{ color: "#FFFFFF" }}
              >
                Create Accounts
              </Typography>
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={() => {
                setSelected(2);
                setStep(1);
                removeSessionStorage();
                sessionStorage.setItem("tiac_sidebar_step", 2);
                sessionStorage.setItem("tiac_sidebar_mobilestep", 1);
              }}
            >
              <Typography className={classes.sidebarItem}>
                Create Accounts
              </Typography>
            </ListItem>
          )}
          {selected == 3 ? (
            <ListItem
              className={classes.selected}
              button
              onClick={() => {
                setSelected(3);
                setStep(1);
              }}
            >
              <Typography
                className={classes.sidebarItem}
                style={{ color: "#FFFFFF" }}
              >
                User Details
              </Typography>
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={() => {
                setSelected(3);
                setStep(1);
                removeSessionStorage();
                sessionStorage.setItem("tiac_sidebar_step", 3);
                sessionStorage.setItem("tiac_sidebar_mobilestep", 1);
              }}
            >
              <Typography className={classes.sidebarItem}>
                User Details
              </Typography>
            </ListItem>
          )}
          {(isSebi) &&
            (selected == 4 ? (
              <ListItem
                className={classes.selected}
                button
                onClick={() => {
                  setSelected(4);
                  setStep(1);
                }}
              >
                <Typography
                  className={classes.sidebarItem}
                  style={{ color: "#FFFFFF" }}
                >
                  Finances
                </Typography>
              </ListItem>
            ) : (
              <ListItem
                button
                onClick={() => {
                  setSelected(4);
                  setStep(1);
                  removeSessionStorage();
                  sessionStorage.setItem("tiac_sidebar_step", 4);
                  sessionStorage.setItem("tiac_sidebar_mobilestep", 1);
                }}
              >
                <Typography className={classes.sidebarItem}>
                  Finances
                </Typography>
              </ListItem>
            ))}

          {profile.profile_type == "sub_admin_tiac_rv" &&
            (selected == 5 ? (
              <ListItem
                className={classes.selected}
                button
                onClick={() => {
                  setSelected(5);
                  setStep(1);
                }}
              >
                <Typography
                  className={classes.sidebarItem}
                  style={{ color: "#FFFFFF" }}
                >
                  Case Managers
                </Typography>
              </ListItem>
            ) : (
              <ListItem
                button
                onClick={() => {
                  setSelected(5);
                  setStep(1);
                  removeSessionStorage();
                  sessionStorage.setItem("tiac_sidebar_step", 5);
                  sessionStorage.setItem("tiac_sidebar_mobilestep", 1);
                }}
              >
                <Typography className={classes.sidebarItem}>
                  Case Managers
                </Typography>
              </ListItem>
            ))}

          {selected == 6 ? (
            <ListItem
              className={classes.selected}
              button
              onClick={() => {
                setSelected(6);
                setStep(1);
              }}
            >
              <Typography
                className={classes.sidebarItem}
                style={{ color: "#FFFFFF" }}
              >
                Arbitrators
              </Typography>
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={() => {
                setSelected(6);
                setStep(1);
                removeSessionStorage();
                sessionStorage.setItem("tiac_sidebar_step", 6);
                sessionStorage.setItem("tiac_sidebar_mobilestep", 1);
              }}
            >
              <Typography className={classes.sidebarItem}>
                Arbitrators
              </Typography>
            </ListItem>
          )}
          {isSebi &&
            (selected == 13 ? (
              <ListItem
                className={classes.selected}
                button
                onClick={() => {
                  setSelected(13);
                  setStep(1);
                }}
              >
                <Typography
                  className={classes.sidebarItem}
                  style={{ color: "#FFFFFF" }}
                >
                  Conciliators
                </Typography>
              </ListItem>
            ) : (
              <ListItem
                button
                onClick={() => {
                  setSelected(13);
                  setStep(1);
                  removeSessionStorage();
                  sessionStorage.setItem("tiac_sidebar_step", 13);
                  sessionStorage.setItem("tiac_sidebar_mobilestep", 1);
                }}
              >
                <Typography className={classes.sidebarItem}>
                  Conciliators
                </Typography>
              </ListItem>
            ))}
          {selected == 7 && (isArb || isKilumaya) ? (
            <ListItem
              className={classes.selected}
              button
              onClick={() => {
                setSelected(7);
                setStep(1);
              }}
            >
              <Typography
                className={classes.sidebarItem}
                style={{ color: "#FFFFFF" }}
              >
                Create Dispute
              </Typography>
            </ListItem>
          ) : isArb || isKilumaya ? (
            <ListItem
              button
              onClick={() => {
                setSelected(7);
                setStep(1);
                removeSessionStorage();
                sessionStorage.setItem("tiac_sidebar_step", 7);
                sessionStorage.setItem("tiac_sidebar_mobilestep", 1);
              }}
            >
              <Typography className={classes.sidebarItem}>
                Create Dispute
              </Typography>
            </ListItem>
          ) : null}
          {selected == 8 ? (
            <ListItem
              className={classes.selected}
              button
              onClick={() => {
                setSelected(8);
                setStep(1);
              }}
            >
              <Typography
                className={classes.sidebarItem}
                style={{ color: "#FFFFFF" }}
              >
                Drive
              </Typography>
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={() => {
                setSelected(8);
                setStep(1);
                removeSessionStorage();
                sessionStorage.setItem("tiac_sidebar_step", 8);
                sessionStorage.setItem("tiac_sidebar_mobilestep", 1);
              }}
            >
              <Typography className={classes.sidebarItem}>Drive</Typography>
            </ListItem>
          )}
          {selected == 9 ? (
            <ListItem
              className={classes.selected}
              button
              onClick={() => {
                setSelected(9);
                setStep(1);
              }}
            >
              <Typography
                className={classes.sidebarItem}
                style={{ color: "#FFFFFF" }}
              >
                Calendar
              </Typography>
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={() => {
                setSelected(9);
                setStep(1);
                removeSessionStorage();
                sessionStorage.setItem("tiac_sidebar_step", 9);
                sessionStorage.setItem("tiac_sidebar_mobilestep", 1);
              }}
            >
              <Typography className={classes.sidebarItem}>Calendar</Typography>
            </ListItem>
          )}
          {isSebi &&
            (selected == 10 ? (
              <ListItem
                className={classes.selected}
                button
                onClick={() => {
                  setSelected(10);
                  setStep(1);
                }}
              >
                <Typography
                  className={classes.sidebarItem}
                  style={{ color: "#FFFFFF" }}
                >
                  Create Meeting
                </Typography>
              </ListItem>
            ) : (
              <ListItem
                button
                onClick={() => {
                  setSelected(10);
                  setStep(1);
                  removeSessionStorage();
                  sessionStorage.setItem("tiac_sidebar_step", 10);
                  sessionStorage.setItem("tiac_sidebar_mobilestep", 1);
                }}
              >
                <Typography className={classes.sidebarItem}>
                  Create Meeting
                </Typography>
              </ListItem>
            ))}
          {isSebi &&
            (selected == 11 ? (
              <ListItem
                className={classes.selected}
                button
                onClick={() => {
                  setSelected(11);
                  setStep(1);
                }}
              >
                <Typography
                  className={classes.sidebarItem}
                  style={{ color: "#FFFFFF" }}
                >
                  Invoices
                </Typography>
              </ListItem>
            ) : (
              <ListItem
                button
                onClick={() => {
                  setSelected(11);
                  setStep(1);
                  removeSessionStorage();
                  sessionStorage.setItem("tiac_sidebar_step", 11);
                  sessionStorage.setItem("tiac_sidebar_mobilestep", 1);
                }}
              >
                <Typography className={classes.sidebarItem}>
                  Invoices
                </Typography>
              </ListItem>
            ))}
        </List>
      </Box>
    );
  };

  const Body = () => {
    return (
      <Box className={classes.body2}>
        <Hidden mdUp>
          <Paper
            style={{
              background: "none",
              border: "none",
              boxShadow: "none",
              display: "flex",
              justifyContent: "flex-start",
              padding: "15px 20px",
            }}
          >
            <Button
              size="large"
              onClick={(e) => {
                setStep(0);
                setSelected(0);
                removeSessionStorage();
              }}
              startIcon={<ArrowBackIosIcon />}
            >
              <FormattedMessage id="buttons.back" />
            </Button>
          </Paper>
        </Hidden>
        <Box className={classes.body}>
          <RenderOptions />
        </Box>
      </Box>
    );
  };

  const MobileView = ({ step }) => {
    switch (step) {
      case 0:
      case "0":
        return <Sidebar />;
      case 1:
      case "1":
        return <Body />;
      default:
        return null;
    }
  };

  return (
    <Box className={classes.root}>
      <Hidden smDown>
        <>
          <Sidebar />
          <Body />
        </>
      </Hidden>
      <Hidden mdUp>
        <MobileView step={step} />
      </Hidden>
    </Box>
  );
}
