import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import {
  isArb,
  isArbitrationConference,
  isDifc,
  isKilumaya,
  isSebi,
  isTiac,
} from "Routes/Routes";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    minWidth: "50rem",
    [theme.breakpoints.down("sm")]: {
      minWidth: "20rem",
    },
  },
  header: {
    background: "rgba(42, 139, 242, 0.05)",
    padding: "2rem 3rem",
    width: "auto !important",
  },
  body: {
    background: "",
  },
  title: {
    margin: "0",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "2rem",
    lineHeight: "1.8rem",
    color: theme.palette.primary.main,
  },
  titletiac: {
    margin: "0",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "2rem",
    lineHeight: "1.8rem",
    color: "#2F80ED",
  },
  content: {
    padding: "2rem 3rem",
  },
}));

export default function DisplayContainer({ title, children }) {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <h3
          className={
            isTiac ||
            isArb ||
            isKilumaya ||
            isDifc ||
            isArbitrationConference ||
            isSebi
              ? classes.titletiac
              : classes.title
          }
        >
          {title}
        </h3>
      </Box>
      <Box className={classes.content}>{children}</Box>
    </Box>
  );
}
