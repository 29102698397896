import {
  Box,
  Button,
  Grid,
  makeStyles,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import CloseModalButton from "Admin/Common/CloseModalButton";
import instance from "api/globalaxios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { gethost } from "slices/disputeSlice";
import { getProfiles } from "slices/profileSlice";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: "10rem auto",
    width: "50%",
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down("sm")]: {
      width: "60%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "70%",
    },
  },
  heading: {
    fontSize: "2rem",
    textAlign: "center",
    margin: "1rem 0 0 0",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },
}));

export default function CreateProfile({
  profileType,
  setProfile,
  setProfiles,
  multiple,
  profile,
  group,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setopen] = useState(false);
  const handleClose = () => {
    setopen(false);
  };
  const handleOpen = () => {
    setopen(true);
  };

  const reload = () => {
    getProfiles(profileType).then((res) => {
      setProfiles(res);
    });
  };

  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [firstName, setFirstName] = useState();
  const [lastname, setLastname] = useState();

  const create = () => {
    dispatch(createProfile())
      .then((res) => {
        if (res.id) {
          reload();
          handleClose();
          setEmail();
          setPhone();
          setFirstName();
          setLastname();
        } else {
          alert("error", res);
        }
      })
      .catch((error) => {
        console.log(error);
        console.log(error.request.status);
        console.log(error.message);
        console.log(error.request.response);
      });
  };

  function createProfile() {
    return async (dispatch) => {
      let data = {
        profile_type: profileType,
        group: group,
        user: {
          email: email,
          password: "Test@123",
          first_name: firstName,
          last_name: lastname,
        },
      };
      if (phone.trim() != "") {
        data["phone_number"] = phone;
      }
      try {
        const response = await instance.post(
          "/customsignup/?host=" + gethost(),
          data,
          {
            headers: {
              "content-type": "application/json",
            },
          }
        );
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  const submit = () => {
    if (email && phone && firstName && lastname) {
      if (
        email.trim() != "" &&
        phone.trim() != "" &&
        firstName.trim() != "" &&
        lastname.trim() != ""
      ) {
        let countspace = (phone.match(/ /g) || []).length;
        if (countspace > 0) {
          if (countspace > 1) {
            alert("Remove extra spaces except after country code");
          } else {
            if (/^\d+$/.test(phone.split(" ")[1])) {
              // alert("corect with countru code");
              create();
            } else {
              alert("incorrect phone number");
            }
          }
        } else {
          if (/^\d+$/.test(phone)) {
            create();
          } else {
            alert("incorrect phone number");
          }
        }
      } else {
        alert("field is empty");
      }
    } else {
      alert("field is empty");
    }
  };

  return (
    <Grid container justify="center">
      <Grid item>
        <Button onClick={handleOpen}>Create account</Button>
        <Modal open={open} onClose={handleClose}>
          <Paper className={classes.paper}>
            <CloseModalButton onClick={handleClose} />
            <Grid container justify="center" spacing={4}>
              <Grid item md={8} sm={9} xs={10}>
                <Typography className={classes.heading}>
                  Insert details
                </Typography>
              </Grid>
            </Grid>
            <Grid container justify="center" spacing={4}>
              <Grid item md={8} sm={9} xs={10}>
                <TextField
                  required
                  autoFocus
                  id="email"
                  name="email"
                  label="Email"
                  variant="outlined"
                  fullWidth={true}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container justify="center" spacing={4}>
              <Grid item md={8} sm={9} xs={10}>
                <TextField
                  required
                  id="first_name"
                  name="first_name"
                  label="First Name"
                  variant="outlined"
                  fullWidth={true}
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container justify="center" spacing={4}>
              <Grid item md={8} sm={9} xs={10}>
                <TextField
                  required
                  id="last_name"
                  name="last_name"
                  label="Last Name"
                  variant="outlined"
                  fullWidth={true}
                  value={lastname}
                  onChange={(e) => {
                    setLastname(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container justify="center" spacing={4}>
              <Grid item md={8} sm={9} xs={10}>
                <TextField
                  required
                  id="phone"
                  name="phone"
                  label="Phone Number"
                  variant="outlined"
                  fullWidth={true}
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Box height="1rem" />
            <Grid container justify="center" spacing={4}>
              <Grid item md={8} sm={9} xs={10} style={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={submit}
                >
                  Create
                </Button>
                <Box height="4rem" />
              </Grid>
            </Grid>
          </Paper>
        </Modal>
      </Grid>
    </Grid>
  );
}
