import moment from "moment-timezone";

/**
 * Convert utc date time string to local timezone moment
 * @param {*} utcDateTimeString
 * @returns moment
 */
export function convertUTCToLocalMomentTime(utcDateTimeString) {
  const localTimezone = getTimeZone()
  const utcDateTime = moment.utc(utcDateTimeString);
  return utcDateTime.tz(localTimezone);
}

/**
 * Returns user's timezone
 */
export function getTimeZone(){
  return moment.tz.guess()
}
