import {
  Box,
  Grid,
  Hidden,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import { format, isSameDay, subDays } from "date-fns";
import GroupDisplay from "features/Chat/GroupDisplay";
import InputBox from "features/Chat/InputBox";
import MembersDisplay from "features/Chat/MembersDisplay";
import Message from "features/Chat/Message";
import VoiceInputBox from "features/Chat/VoiceInputBox";
import DividerWithText from "features/Divider/DividerWithText";
import { useAudioRecorder, useTimer } from "hooks";
import React from "react";
import { useDispatch } from "react-redux";
import { uploadFile } from "slices/groupSlice";
import { createGroupName } from "utils";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
    header: {
      borderRadius: "inherit",
      flexBasis: "5rem",
      minHeight: "5rem",
      width: "100%",
      padding: "0 3rem",
      borderBottom: `1px solid ${theme.palette.border}`,
      background: theme.palette.common.grey,
      [theme.breakpoints.down("sm")]: {
        padding: "0 0.1rem",
        minHeight: "6rem",
      },
    },
    heading: {
      fontWeight: "bold",
    },
    messages: {
      overflow: "scroll",
      flexGrow: "1",
      borderBottom: `0.2rem solid ${theme.palette.border}`,
      padding: "0 2rem",
      boxSizing: "border-box",
    },
    footer: {
      padding: "0rem 2rem",
    },
    members: {
      paddingRight: "6rem",
      [theme.breakpoints.down("sm")]: {
        paddingRight: "3rem",
      },
    },
    date: {
      border: '1px solid #ededed',
      padding: '6px 15px',
      borderRadius: '16px',
      fontSize: '0.9rem',
      background: 'white',
      color: 'black',
      margin: '1rem 0',
    }
  };
});

const getChatDate = (timestamp) => {
  let currentDate = new Date();
  if (isSameDay(currentDate, timestamp)) {
    return 'Today';
  } else if (isSameDay(subDays(currentDate, 1), timestamp)) {
    return "Yesterday";
  } else {
    return format(timestamp, "dd-MM-yyyy");
  }
}

export default function ChatBody({
  group,
  classes: parentClasses,
  messages,
  members,
  rightDocOpen,
  typing,
  messageContainer,
  smallScreen,
  setStep,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [isVoiceInput, setIsVoiceInput] = React.useState(false);
  const dispatch = useDispatch();

  const [isRecording, blobURL, audioFile, startRecording, stopRecording] =
    useAudioRecorder();

  const [toggle, reset, seconds] = useTimer();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleStartRecording = () => {
    toggle();
    setIsVoiceInput(true);
    startRecording();
  };

  const handleStopRecording = async () => {
    reset();
    setIsVoiceInput(false);
    stopRecording();
  };

  const handleRecordingSend = async () => {
    reset();
    setIsVoiceInput(false);
    let file = await stopRecording();
    handleFileUpload(file);
  };

  const handleFileUpload = (file) => {
    dispatch(uploadFile({ file, groupId: group._id, msg: "Audio message" }));
  };

  const showDate = (timeStamp, prevTimeStamp) => {
    if(!isSameDay(prevTimeStamp,timeStamp)){
      return (
        <Box style={{display:'flex',justifyContent:'center'}}>
          <Typography className={classes.date}>
            {getChatDate(timeStamp)}
          </Typography>
        </Box>
      )
    }
  }

  const groupName = createGroupName(group.name);

  return (
    <Box className={parentClasses.content}>
      <Box className={classes.container}>
        <Box
          className={classes.header}
          bgcolor={grey["200"]}
          display="flex"
          alignItems="center"
        >
          <Hidden mdUp>
            <IconButton
              onClick={() => {
                setStep(0);
              }}
            >
              <ArrowBackIosIcon />
            </IconButton>
          </Hidden>
          <Hidden smUp>
            <Box height="0.5rem" />
          </Hidden>
          <Grid container justify="space-between">
            <Grid item sm={7} xs={8}>
              <GroupDisplay
                group={group}
                name={groupName}
                fontSize="large"
                caption={false}
                rightDocOpen={rightDocOpen}
                setStep={setStep}
                smallScreen={smallScreen}
                unreadStatus={false}
              />
            </Grid>
            <Grid item sm={1} xs={1} className={classes.members}>
              <Box onClick={handleOpen} style={{ cursor: "pointer" }}>
                {Object.keys(members).length > 0 && (
                  <PeopleAltOutlinedIcon fontSize="large" color="primary" />
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box p="0 3rem" className={classes.messages} ref={messageContainer}>
          {messages.map((message,index) => {
            const unreads = group?.counters?.unreads;
            const timeStamp = message.ts.$date;
            const prevTimeStamp = messages[index-1]?.ts?.$date;
            return (
              <>
                {showDate(timeStamp,prevTimeStamp)}
                {(index === messages?.length - unreads) && (
                  <DividerWithText>
                    <Typography style={{border:'1px solid #e5e5e5', padding:'3px 5px', borderRadius:'4px'}}>
                      {unreads} unread {unreads === 1 ? 'message' : 'messages'}
                    </Typography>
                  </DividerWithText>  
                )}
                <Message
                  message={message}
                  domId={message._id}
                  key={message._id}
                />
              </>
            );
          })}

          <Box display="flex" justifyContent="flex-end" p="0.4rem 0">
            {typing.value && typing.groupId === group._id && (
              <Typography variant="caption">{`${typing.username} is typing...`}</Typography>
            )}
          </Box>
          {/* <ScrollTo /> */}
        </Box>
        <Box className={classes.footer}>
          <Box className="hearingroomstep2">
            {isVoiceInput ? (
              <VoiceInputBox
                handleCancelRecording={handleStopRecording}
                handleFileUpload={handleFileUpload}
                handleRecordingSend={handleRecordingSend}
                seconds={seconds}
              />
            ) : (
              <InputBox
                groupId={group._id}
                handleStartRecording={handleStartRecording}
                members={members}
              />
            )}
          </Box>
        </Box>
      </Box>
      here
      <MembersDisplay members={members} open={open} handleClose={handleClose} />
    </Box>
  );
}
