import { IconButton } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PersonIcon from "@material-ui/icons/Person";
import FormattedMessage from "features/common/TranslatedMessage";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { logout, setLogOut } from "slices/loginSlice";
import { Typography } from "@material-ui/core";
import { isArb, isDifc, isHotStar, isKilumaya, isMuthoot, isSebi, isStar, isTiac } from "Routes/Routes";
import { useState } from "react";
import { useEffect } from "react";

// const options = ["Profile", "Logout"];

const options = [
  <FormattedMessage id="user.profile" />,
  <FormattedMessage id="auth.logout" />,
];

const StyledMenu = withStyles({
  list: { padding: "0" },
  paper: { borderRadius: "0", width: "8%" },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    padding: "0.8rem",
    color: (isSebi || isTiac || isDifc || isArb || isKilumaya || isMuthoot) ? theme.palette.primary.main : "#2F80ED",
    // "&:focus": {
    //   color: "#fff",

    //   backgroundColor: theme.palette.primary.main,
    //   "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
    //     color: "#fff",
    //   },
    // },
    "&&:hover": {
      backgroundColor: (isSebi || isTiac || isDifc || isArb || isKilumaya || isMuthoot) ? theme.palette.primary.main : "#2F80ED",
      color: "#fff",
    },
    // "& .Mui-selected": {
    //   backgroundColor: theme.palette.primary.main,
    // },
  },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    color: "inherit",
  },
  list: {
    padding: "0",
  },
  profileIcon: {
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    width:' 40px',
    height: '40px',
    color: '#FFF',
    fontSize: '1rem',
    background: theme.palette.primary.main,
  }
}));

export default function Tiac_ProfileDropdown({ profile }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(2);
  const history = useHistory();
  const [namePrefix, setNamePrefix] = useState('');
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    // setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(()=>{
    if(profile?.user){
      setNamePrefix(`${profile?.user?.first_name?.charAt(0)}${profile?.user?.last_name?.charAt(0)}`);
    }
  },[profile])

  return (
    <div className={classes.root}>
      {(isSebi || isTiac || isDifc || isArb || isKilumaya || isMuthoot) ? (
        <IconButton
          aria-controls="lock-menu"
          aria-haspopup="true"
          color="inherit"
          data-testid="profile-drop"
          onClick={handleClickListItem}
          style={{padding: '8px'}}
        >
          <div className={classes.profileIcon}>{namePrefix ? namePrefix : <PersonIcon/>}</div>
        </IconButton>
      ) : (
        <Typography
          style={
            (isHotStar || isStar)
              ? {
                  cursor: "pointer",
                  color: "#4F4F4F",
                  fontFamily: `'Inter',sans-serif`,
                  fontSize: "20px",
                }
              : {
                  cursor: "pointer",
                  color: "#4F4F4F",
                  fontFamily: `'Inter',sans-serif`,
                }
          }
          onClick={handleClickListItem}
        >
          Profile
        </Typography>
      )}
      <StyledMenu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option, index) => (
          <StyledMenuItem
            key={index}
            selected={index === selectedIndex}
            onClick={(event) => {
              handleMenuItemClick(event, index);
              if (index === 0) {
                history.push(`/profile`);
              } else {
                sessionStorage.clear();
                dispatch(logout());
                dispatch(setLogOut());
                if(isHotStar || isStar || isMuthoot) history.push('/')
                else history.push("/login");
              }
            }}
          >
            {option}
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </div>
  );
}
