import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { CLAIMANT, RESPONDENT } from "app/constants";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { isArbitrationConference, isDifc } from "Routes/Routes";
import { createCase } from "slices/casesSlice";
import { createDispute, updateDispute } from "slices/disputeSlice";
import { getProfiles } from "slices/profileSlice";
import SetProfile from "./CreateDispute/SetProfile";
import { getCompanyProducts } from "slices/companySlice";
import ModeSelect from "./CreateDispute/ModeSelect";
import { joinHearingRoomsDifc } from "./Assign";

const useStyles = makeStyles((theme) => ({
  createdispute: {
    fontSize: "24px",
    lineHeight: "28px",
  },
  box: { textAlign: "left", margin: "1rem 0 2rem 0" },
}));

export default function CreateDispute() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { profile } = useSelector((state) => state.profile, shallowEqual);

  const [company_product, setCompany_product] = useState();

  const [claimant, setClaimant] = useState();
  const [respondent, setrespondent] = useState();
  const [claimantLawyer, setClaimantLawyer] = useState([]);
  const [respondentLawyer, setRespondentLawyer] = useState([]);
  const [professional, setProfessional] = useState([]);
  const [mode, setmode] = useState();

  const [claimantLoading, setClaimantLoading] = useState(true);
  const [respondentLoading, setRespondentLoading] = useState(true);
  const [claimantLawyerLoading, setClaimantLawyerLoading] = useState(true);
  const [respondentLawyerLoading, setRespondentLawyerLoading] = useState(true);
  const [professionalLoading, setProfessionalLoading] = useState(false);

  const [profilesClaimant, setProfilesClaimant] = useState([]);
  const [profilesRespondent, setProfilesRespondent] = useState([]);
  const [profilesClaimantLawyer, setProfilesClaimantLawyer] = useState([]);
  const [profilesRespondentLawyer, setProfilesRespondentLawyer] = useState([]);
  const [profilesProfessional, setProfilesProfessional] = useState([]);

  React.useEffect(() => {
    getCompanyProducts(profile.company).then((res) => {
      setCompany_product(res?.[0]?.id);
    });
  }, []);

  React.useEffect(() => {
    getProfiles("general_rv").then((res) => {
      setProfilesClaimant(res);
      setProfilesRespondent(res);
      setClaimantLoading(false);
      setRespondentLoading(false);
    });
    getProfiles("lawyer_rv").then((res) => {
      setProfilesClaimantLawyer(res);
      setProfilesRespondentLawyer(res);
      setClaimantLawyerLoading(false);
      setRespondentLawyerLoading(false);
    });
  }, []);

  React.useEffect(() => {
    if (mode) {
      getProfiles(mode === "arbitration" ? "arbitrator_rv" : "mediator").then(
        (res) => {
          setProfilesProfessional(res);
          setProfessionalLoading(false);
        }
      );
    }
    setProfessional([])
  }, [mode]);

  const submit = () => {
    if (claimant && respondent && mode) {
      if (claimant.id === respondent.id) {
        alert("Select different profile for claimant and respondent");
      } else {
        let name =
          claimant.user.first_name +
          " " +
          claimant.user.last_name +
          " vs " +
          respondent.user.first_name +
          " " +
          respondent.user.last_name;
        if (isArbitrationConference) {
          name = "Linklaters vs CARTAL Conference";
        }
        let description = name;
        let professionals = [];
        professional.forEach((element) => {
          professionals.push(element.id);
        });
        let claimantLawyers = [];
        claimantLawyer.forEach((element) => {
          claimantLawyers.push(element.id);
        });
        let respondentLawyers = [];
        respondentLawyer.forEach((element) => {
          respondentLawyers.push(element.id);
        });
        let disputeBody = {
          name: name,
          description: description,
          flow_type : mode,
          platform_type: "rv",
          company_product: company_product,
          professionals: professionals,
          rv_claimant_choose_step_complete: true,
          rv_claimant_lawyer_step_complete: true,
          rv_respondent_choose_step_complete: true,
          rv_respondent_lawyer_step_complete: true,
          approved: true,
          rv_arbitrator_step_complete: true,
          rv_tiac_payment_status: "complete",
          tiac_dispute_step: "form_complete",
        };
        if (isArbitrationConference) {
          disputeBody["approved"] = true;
        }
        dispatch(createDispute(disputeBody)).then((response) => {
          let createCaseBody = {
            summary: description,
            description: description,
            profile: claimant.id,
            dispute: response,
            type: CLAIMANT,
            lawyer_rv: claimantLawyers,
          };
          dispatch(createCase(createCaseBody)).then(() => {
            let respondentCaseBody = {
              summary: description,
              description: description,
              profile: respondent.id,
              dispute: response,
              type: RESPONDENT,
              lawyer_rv: respondentLawyers,
            };
            dispatch(createCase(respondentCaseBody)).then(() => {
              if (isDifc) {
                dispatch(joinHearingRoomsDifc(response));
              }
              setTimeout(() => {
                alert("dispute created");
              }, 500);
            });
          });
        });
      }
    } else {
      alert("Please select mandatory fields : Mode, Claimant and Defendant");
    }
  };

  if (
    claimantLoading ||
    respondentLoading ||
    claimantLawyerLoading ||
    respondentLawyerLoading ||
    professionalLoading
  ) {
    return <CircularCenteredLoader />;
  }

  return (
    <Grid container justify="center">
      <Grid item md={10} sm={11} xs={11}>
        <Box className={classes.box}>
          <Typography className={classes.createdispute}>
            Create Dispute
          </Typography>
        </Box>
        <ModeSelect mode={mode} setmode={setmode} />
        <Grid
          container
          style={{ margin: "2rem 0 0 0" }}
          justify="flex-start"
          spacing={2}
        >
          <Grid item md={12} sm={12} xs={12}>
            <SetProfile
              profileType="general_rv"
              multiple={false}
              heading="Claimant *"
              setProfile={setClaimant}
              profile={claimant}
              profiles={profilesClaimant}
              setProfiles={setProfilesClaimant}
              group={profile.group}
            />
          </Grid>
        </Grid>
        <Grid
          container
          style={{ margin: "2rem 0 0 0" }}
          justify="flex-start"
          spacing={2}
        >
          <Grid item md={12} sm={12} xs={12}>
            <SetProfile
              profileType="general_rv"
              multiple={false}
              heading="Defendant *"
              setProfile={setrespondent}
              profile={respondent}
              profiles={profilesRespondent}
              setProfiles={setProfilesRespondent}
              group={profile.group}
            />
          </Grid>
        </Grid>
        <Grid
          container
          style={{ margin: "2rem 0 0 0" }}
          justify="flex-start"
          spacing={2}
        >
          <Grid item md={12} sm={12} xs={12}>
            <SetProfile
              profileType="lawyer_rv"
              multiple={true}
              heading="Claimant Counsel"
              setProfile={setClaimantLawyer}
              profile={claimantLawyer}
              profiles={profilesClaimantLawyer}
              setProfiles={setProfilesClaimantLawyer}
              group={profile.group}
            />
          </Grid>
        </Grid>
        <Grid
          container
          style={{ margin: "2rem 0 0 0" }}
          justify="flex-start"
          spacing={2}
        >
          <Grid item md={12} sm={12} xs={12}>
            <SetProfile
              profileType="lawyer_rv"
              multiple={true}
              heading="Defendant Counsel"
              setProfile={setRespondentLawyer}
              profile={respondentLawyer}
              profiles={profilesRespondentLawyer}
              setProfiles={setProfilesRespondentLawyer}
              group={profile.group}
            />
          </Grid>
        </Grid>
        {mode && (
          <Grid
            container
            style={{ margin: "2rem 0 0 0" }}
            justify="flex-start"
            spacing={2}
          >
            <Grid item md={12} sm={12} xs={12}>
              <SetProfile
                profileType={
                  mode === "arbitration" ? "arbitrator_rv" : "mediator"
                }
                multiple={true}
                heading={mode === "arbitration" ? "Arbitrator" : "Mediator"}
                setProfile={setProfessional}
                profile={professional}
                profiles={profilesProfessional}
                setProfiles={setProfilesProfessional}
                group={profile.group}
              />
            </Grid>
          </Grid>
        )}
        <Box height="2rem" />
        <Button
          type="submit"
          variant="outlined"
          color="primary"
          onClick={() => {
            submit();
          }}
        >
          <Typography>Create</Typography>
        </Button>
        <Box height="5rem" />
      </Grid>
    </Grid>
  );
}
