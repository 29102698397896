import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import instance from "api/globalaxios";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { formatDate } from "utils";
import { deAssignProfile } from "../ADRP";
import EditProfile from "../EditProfile";
import ViewProfile from "../ViewProfile";

const useStyles = makeStyles((theme) => ({
  blackheading: {
    fontWeight: "550",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.05em",
    color: "#2C2C2C",
    padding: "17px 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "14px",
    },
  },
  inactivetext: {
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#6E6893",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      lineHeight: "12px",
    },
  },
  activetext: {
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#2F80ED",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      lineHeight: "12px",
    },
  },
  greytext: {
    fontSize: "12px",
    lineHeight: "14px",
    color: "#828282",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      lineHeight: "12px",
    },
  },
  greyheading: {
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "14px",
    letterSpacing: "0.05em",
    color: "#828282",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      lineHeight: "12px",
    },
  },
  grey: {
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.05em",
    color: "#828282",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "14px",
    },
  },
  black: {
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.05em",
    color: "#2C2C2C",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "16px",
    },
  },
  grid: {
    paddingLeft: "1.6rem",
    cursor: "pointer",
    // background: "#ECEEF8",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0.9rem",
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0.3rem",
    },
  },
  grid2: {
    paddingLeft: "1.6rem",
    cursor: "pointer",
    background: "#ECEEF8",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0.9rem",
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0.3rem",
    },
  },
  inactive: {
    background: "#F2F0F9",
    borderRadius: "22px",
    padding: "4px 10px",
  },
  active: {
    background: "#ECEEF8",
    borderRadius: "22px",
    padding: "4px 10px",
  },
  blueUnderlined: {
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: 400,
    color: "#2F80ED",
    textDecoration: "underline",
    cursor: "pointer",
  },
}));

export default function CaseManagerProfile({
  name,
  // status: initialStatus,
  last_login,
  open,
  closed,
  id,
  item,
  addprofile,
  removeprofile,
  initialCheckbox,
  refresh,
}) {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selected, setSelected] = useState(initialCheckbox);
  React.useEffect(() => {
    setSelected(initialCheckbox);
  }, [initialCheckbox]);
  const [expanded, setExpanded] = useState(false);
  const [disputesData, setDisputesData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const [stopClickBackground, setStopClickBackground] = useState(false);

  const [openmodal3, setOpenmodal3] = useState(false);

  const handleOpenModal3 = (e) => {
    e.stopPropagation();
    setOpenmodal3(true);
    setStopClickBackground(true);
  };

  const handleCloseModal3 = (e) => {
    e.stopPropagation();
    setOpenmodal3(false);
    setStopClickBackground(false);
    refresh();
  };

  const [openmodal4, setOpenmodal4] = useState(false);

  const handleOpenModal4 = (e) => {
    e.stopPropagation();
    setOpenmodal4(true);
    setStopClickBackground(true);
  };

  const handleCloseModal4 = (e) => {
    e.stopPropagation();
    setOpenmodal4(false);
    setStopClickBackground(false);
  };

  const handleSelectedChange = (e) => {
    e.stopPropagation();
    setSelected(e.target.checked);
    if (e.target.checked) {
      addprofile(item);
    } else {
      removeprofile(item);
    }
  };

  const handleExpandChange = (e) => {
    setExpanded(!expanded);
  };

  function getUserDisputes(id) {
    return async (dispatch) => {
      setLoading(true);
      try {
        const response = await instance.get(
          `/api/disputedetails/?profile=${id}`
        );
        setDisputesData(response.data);
        setLoading(false);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  const handleActivateUser = (e) => {
    e.stopPropagation();
    // setStatus(true);
    dispatch(activateUser(id)).then(() => {
      refresh();
    });
  };
  const handleDeactivateUser = (e) => {
    e.stopPropagation();
    // setStatus(false);
    dispatch(deactivateUser(id)).then(() => {
      refresh();
    });
  };

  function activateUser(id) {
    return async (dispatch) => {
      try {
        const response = await instance.put(`/updateprofile/${id}/`, {
          is_active: 1,
        });
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }
  function deactivateUser(id) {
    return async (dispatch) => {
      try {
        const response = await instance.put(`/updateprofile/${id}/`, {
          is_active: 0,
        });
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  return (
    <>
      <Grid
        container
        justify="flex-start"
        className={expanded ? classes.grid2 : classes.grid}
        // onClick={(e) => {
        //   if (!stopClickBackground) {
        //     handleExpandChange();
        //     if (!expanded) dispatch(getUserDisputes(id)).then((res) => {});
        //   }
        // }}
      >
        <Grid
          item
          md={1}
          sm={2}
          xs={2}
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
          // style={{ textAlign: "left" }}
        >
          <FormControlLabel
            style={{ marginRight: "auto" }}
            control={
              <Checkbox
                checked={selected}
                onChange={handleSelectedChange}
                name="selectall"
                style={{
                  color: "#2F80ED",
                }}
              />
            }
            label=""
          />
          <IconButton
            style={{ marginRight: "auto", padding: "12px 0" }}
            aria-label="more"
            onClick={(e) => {
              if (!stopClickBackground) {
                handleExpandChange();
                if (!expanded) dispatch(getUserDisputes(id)).then((res) => {});
              }
            }}
          >
            {!expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </IconButton>
        </Grid>
        <Grid
          item
          md={4}
          sm={4}
          xs={4}
          style={{ textAlign: "left" }}
          onClick={(e) => {
            if (!stopClickBackground) {
              handleExpandChange();
              if (!expanded) dispatch(getUserDisputes(id)).then((res) => {});
            }
          }}
        >
          <Typography className={classes.blackheading}>{name}</Typography>
        </Grid>
        <Grid
          item
          md={3}
          sm={3}
          xs={3}
          style={{ textAlign: "left", marginTop: "11px" }}
          onClick={(e) => {
            if (!stopClickBackground) {
              handleExpandChange();
              if (!expanded) dispatch(getUserDisputes(id)).then((res) => {});
            }
          }}
        >
          <Grid container justify="space-between" direction="column">
            <Grid item>
              <Grid container justidy="flex-start">
                <Grid item>
                  <Box
                    className={
                      item.user.is_active ? classes.active : classes.inactive
                    }
                  >
                    <Typography
                      className={
                        item.user.is_active
                          ? classes.activetext
                          : classes.inactivetext
                      }
                    >
                      {item.user.is_active ? "Active" : "Inactive"}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography className={classes.greytext}>
                Last login: {last_login ? formatDate(last_login) : "--"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={3}
          sm={2}
          xs={2}
          style={{ textAlign: "left" }}
          onClick={(e) => {
            if (!stopClickBackground) {
              handleExpandChange();
              if (!expanded) dispatch(getUserDisputes(id)).then((res) => {});
            }
          }}
        >
          <Typography className={classes.blackheading}>{open+closed}</Typography>
        </Grid>
        <Grid
          item
          md={1}
          sm={1}
          xs={1}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <IconButton
            aria-label="more"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MenuItem onClick={handleOpenModal3}>Edit</MenuItem>
            {openmodal3 && (
              <EditProfile
                openmodal={openmodal3}
                handleCloseModal={handleCloseModal3}
                profileData={item}
              />
            )}
            <MenuItem onClick={handleOpenModal4}>View Profile</MenuItem>
            {openmodal4 && (
              <ViewProfile
                openmodal={openmodal4}
                handleCloseModal={handleCloseModal4}
                profileData={item}
              />
            )}
            {!item.user.is_active ? (
              <MenuItem
                onClick={handleActivateUser}
                style={{
                  fontSize: "14px",
                  letterSpacing: "0.05em",
                  color: "#007F00",
                }}
              >
                Activate User
              </MenuItem>
            ) : (
              <MenuItem
                onClick={handleDeactivateUser}
                style={{
                  fontSize: "14px",
                  letterSpacing: "0.05em",
                  color: "#007F00",
                }}
              >
                Deactivate User
              </MenuItem>
            )}
            <Divider />
          </Menu>
        </Grid>
      </Grid>
      {expanded && (
        <>
          <Grid
            container
            direction="column"
            style={{ background: "#ECEEF8", padding: "10px" }}
          >
            <Grid item>
              <Box height="1rem"></Box>
              <Grid container justify="flex-start">
                <Grid item sm={1} style={{ textAlign: "left" }}></Grid>
                <Grid item sm={2} xs={3} style={{ textAlign: "left" }}>
                  <Typography className={classes.greyheading}>
                    DISPUTE ID
                  </Typography>
                </Grid>
                <Grid item sm={4} xs={3} style={{ textAlign: "left" }}>
                  <Typography className={classes.greyheading}>
                    DISPUTE DETAILS
                  </Typography>
                </Grid>
                <Grid item sm={2} xs={3} style={{ textAlign: "center" }}>
                  <Typography className={classes.greyheading}>MODE</Typography>
                </Grid>
                <Grid item sm={2} xs={3} style={{ textAlign: "right" }}>
                  {/* <Typography className={classes.greyheading}>
                    CREATED
                  </Typography> */}
                </Grid>
              </Grid>
            </Grid>
            {loading ? (
              <CircularCenteredLoader />
            ) : (
              disputesData.map((dispute) => {
                return (
                  <Grid item key={dispute.id}>
                    <Box height="1rem"></Box>
                    <Grid
                      container
                      justify="flex-start"
                      style={{ background: "#ECEEF8" }}
                    >
                      <Box height="2rem"></Box>
                      <Grid item sm={1} style={{ textAlign: "left" }}></Grid>
                      <Grid item sm={2} xs={3} style={{ textAlign: "left" }}>
                        <Typography className={classes.grey}>
                          {dispute.id}
                        </Typography>
                      </Grid>
                      <Grid item sm={4} xs={3} style={{ textAlign: "left" }}>
                        <Typography className={classes.black}>
                          {dispute.name}
                        </Typography>
                      </Grid>
                      <Grid item sm={2} xs={3} style={{ textAlign: "center" }}>
                        <Typography className={classes.black}>
                          {dispute.platform_type == "cdr" && "CDR"}
                          {dispute.platform_type == "grievance" && "GR"}
                          {dispute.platform_type == "rv" && "RV"}
                          {dispute.platform_type == "nbf" && "NBF"}
                        </Typography>
                      </Grid>
                      <Grid item sm={2} xs={3} style={{ textAlign: "right" }}>
                        <Typography
                          className={classes.blueUnderlined}
                          onClick={() => {
                            deAssignProfile(item.id, dispute.id).then(() => {
                              refresh();
                            });
                          }}
                        >
                          Remove
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })
            )}
          </Grid>
        </>
      )}
    </>
  );
}
