import React, { Fragment, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getMuthootBranches, getQuestions } from "slices/questionSlice";
import Questions from "./Questions";
import "intro.js/introjs.css";
import { Steps } from "intro.js-react";
import { useLocation } from "react-router-dom";
import { isDev, isMuthoot } from "Routes/Routes";

export default function QuestionsContainer({ profile }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getQuestions(activeDispute.company_product, activeCase.type));
  }, []);

  const answerLoading = useSelector((state) => state.answers.loading);

  let {
    questions: { questions },
    disputes: { activeDispute },
    cases: { activeCase },
  } = useSelector((state) => state, shallowEqual);

  const location = useLocation();
  const user = useSelector((state) => state.user.user);
  console.log("lol", location.pathname.split("/"));
  console.log(user);

  useEffect(()=>{
    if(isMuthoot && questions?.length > 0){
      const indexMuthootBranch = questions.findIndex((ques)=> ques.name === "Please add the name of the Muthoot Finance Branch where you encountered difficulty.");
      if(indexMuthootBranch > -1 && questions[indexMuthootBranch]['type'] === 'simple'){
        const url = `api/disputes/${activeDispute?.id}/branches/`;
        dispatch(getMuthootBranches(url,indexMuthootBranch,JSON.parse(JSON.stringify(questions))));
      }
    }
  },[questions])

  const [showGuide, setShowGuide] = useState(
    location.pathname.split("/")[1] == "disputes" && (user.id == 473 || user.id == 214) &&!isDev
  );

  const exit = (t) => {
    setShowGuide(false);
  };

  const steps = [
    // {
    //   element: ".introstep1",
    //   intro: "Select the company you wish to file a grievance against.",
    // },
    {
      element: ".introstep2",
      intro: "Fill some answers.",
    },
    // {
    //   element: ".introstep3",
    //   intro: "Upload any necessary documents.",
    // },
    // {
    //   element: ".introstep4",
    //   intro: "Review your grievance details",
    // },
    // {
    //   element: ".introstep5",
    //   intro: "Download your automatically generated grievance here!",
    // },
  ];

  return (
    <Fragment>
      {questions.length > 0 && (
        <>
          <Steps
            enabled={showGuide}
            steps={steps}
            initialStep={0}
            onExit={exit}
            options={{
              hideNext: false,
              hidePrev: true,
              keyboardNavigation: true,
              exitOnEsc: true,
              exitOnOverlayClick: false,
              showBullets: false,
              showStepNumbers: false,
              disableInteraction: true,
              doneLabel: "Next"
            }}
          />
          <Questions
            questions={questions}
            activeDispute={activeDispute}
            activeCase={activeCase}
            answerLoading={answerLoading}
            profile={profile}
          />
        </>
      )}
    </Fragment>
  );
}
