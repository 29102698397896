import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { errorInterceptor } from "api/globalaxios";
import { IntlProviderWrapper } from "contexts/IntlContext";
import { SnackbarProvider } from "contexts/SnackbarContext";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import theme from "theme";
import App from "./App";
import store from "./app/store";
import "./index.css";
import { isArb, isDifc, isKilumaya, isMuthoot, isPaytm, isSebi, isStar, isTiac } from "Routes/Routes";
import NewTheme from "NewTheme";
import "./i18n.js";
import FullPageSpinner from "features/common/FullPageSpinner";

errorInterceptor(store);

const Main = () => (
  <React.StrictMode>
    <Suspense fallback={<FullPageSpinner/>}>
      <Provider store={store}>
        <IntlProviderWrapper>
          <ThemeProvider theme={isSebi || isTiac || isDifc || isArb || isKilumaya || isPaytm || isStar || isMuthoot ? NewTheme : theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <SnackbarProvider>
                <App />
              </SnackbarProvider>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </IntlProviderWrapper>
      </Provider>
    </Suspense>
  </React.StrictMode>
);

ReactDOM.render(<Main />, document.getElementById("root"));
