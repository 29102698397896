export const CLAIMANT = "claimant";
export const RESPONDENT = "respondent";
export const VENDOR = "vendor";
export const GENERAL = "general";
export const ARBITRATOR = "arbitrator";
export const MEDIATOR = "mediator";
export const ARBITRATION = "arbitration";
export const ADVOCATE = "lawyer";
export const MEDIATION = "mediation";
export const NEGOTIATION = "negotiation";
export const ADJUDICATION = "adjudication";
export const GRIEVANCE_REDRESSAL = "grievance redressal";
export const GENERAL_RV = "general_rv";

export const WITNESS_RV = "witness_rv";
export const ARBITRATOR_RV = "arbitrator_rv";
export const ADVOCATE_RV = "lawyer_rv";
export const SECRETARIAT = "secretariat_rv";

export const CLAIMANT_COMPLETE = "claimant_complete";
export const UPLOAD_COMPLETE = "upload_complete";
export const COMPLETE = "complete";

//case Status
export const REVIEW_COMPLETE = "review_complete";
// rv disputes status values
export const RESPONDENT_COMPLETE = "respondent_complete";
export const RESPONDENT_INPROGRESS = "respondent_inprogress";
export const CLAIMANT_ADVOCATE_COMPLETE = "claimant_lawyer_complete";
export const RESPONDENT_ADVOCATE_COMPLETE = "respondent_lawyer_complete";
export const ARBITRATOR_COMPLETE = "arbitrator_complete";
export const NEW = "new";
export const IO_INPROGRESS = 'io_inprogress';
export const IO_COMPLETE = 'io_complete';
export const MD_INPROGRESS = 'md_inprogress';
export const MD_COMPLETE = 'md_complete';

export const AGREEMENT_SUCCESS = "success";
export const AGREEMENT_FAILED = "failure";

export const LOCAL_AUTH = "local";

export const NBF_LOGIN = "NBF_LOGIN";
// export const NBF_URL = "localhost";
export const NBF_URL = "newsbroadcastersfederation.webnyay.in";

export const HOTSTAR_LOGIN = "HOTSTAR_LOGIN";
// export const HOTSTAR_URL = "localhost";
export const HOTSTAR_URL = "hotstar.webnyay.in";
export const DEV_HOTSTAR_URL = "dev-hotstar.webnyay.in";
export const STAR_URL = "star.webnyay.ai";
export const Muthoot_URL = "muthoot.webnyay.ai";
export const VIACOM_URL = "abc.webnyay.in";
export const CDR_URL = "cdr.webnyay.in";
export const WEBNYAY_URL = "app.webnyay.in";
export const DEV_WEBNYAY_URL = "dev-app.webnyay.in";
export const BARANDBENCH_URL = "barandbench.webnyay.in";
export const DIGIPUB_URL = "digipub.webnyay.in";
export const NSE_URL = "nse.webnyay.in";
export const TIAC_URL = "tiac.webnyaya.com";
export const ARB_URL = "arb.webnyay.in";
export const KILUMAYA_URL = "kilumaya.webnyay.ai";
export const TIAC_URL_STATIC = "webnyaya.com";
export const MOOT_URL = "moot.webnyay.in";
export const VIKALP_URL = "vikalp.webnyay.in";
export const SEBI_URL = "smartodr.webnyay.ai";
export const NDML_FORM_URL_DEV = "https://pilot.surepay.ndml.in/SurePayPayment/sp/processRequest";
export const NDML_FORM_URL = "https://surepay.ndml.in/SurePayPayment/sp/processRequest";
export const STATES = ['AHMEDABAD','ANDAMAN AND NICOBAR', 'ANDHRA PRADESH', 'ARUNACHAL PRADESH', 'ASSAM', 'BIHAR', 'CHANDIGARH', 'CHHATTISGARH', 'DADRA & NAGAR HAVELI', 'DAMAN & DIU', 'DELHI', 'GOA', 'GUJARAT', 'HARYANA', 'HIMACHAL PRADESH', 'JAMMU & KASHMIR', 'JHARKHAND', 'KARNATAKA', 'KERALA', 'MADHYA PRADESH', 'MAHARASHTRA', 'MANIPUR', 'ODISHA', 'PONDICHERRY(UT)', 'PUNJAB', 'RAJASTHAN', 'SIKKIM', 'TAMIL NADU', 'TELANGANA', 'TRIPURA', 'UTTAR PRADESH', 'UTTARAKHAND', 'WEST BENGAL'];
