import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { isTiac } from "Routes/Routes";
import Tabs from "features/Tabs/Tabs";
import React, { useEffect } from "react";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  caseName: {
    fontSize: "1.15rem",
  },
  blue: {
    fontFamily: `Satoshi-Bold`,
    color: `${theme.palette.primary.main}`,
    textAlign: "right",
    cursor: "pointer",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.border}`,
  },
}));

export default function SidebarDisputes({
  profile,
  disputesActiveClaimant,
  disputesResolvedClaimant,
  disputesActiveRespondent,
  disputesResolvedRespondent,
  disputesActiveArbitrator,
  disputesResolvedArbitrator,
  history,
}) {
  const classes = useStyles();
  // const [role, setRole] = useState('claimant');
  const [disputeType, setDisputeType] = useState("active");
  const [disputes, setDiputes] = useState([]);

  // const roleTabs = [
  // 	{
  // 		id: 'claimant',
  // 		label: 'As Claimant',
  // 	},
  // 	{
  // 		id: 'respondent',
  // 		label: 'As Respondent',
  // 	}
  // ]

  const disputeTypeTabs = [
    {
      id: "active",
      label: "Open",
    },
    {
      id: "resolved",
      label: "Closed",
    },
  ];

  useEffect(() => {
    // if(role === 'claimant'){
    // 	if(disputeType === 'active'){
    // 		setDiputes(disputesActiveClaimant);
    // 	}
    // 	else{
    // 		setDiputes(disputesResolvedClaimant);
    // 	}
    // }
    // else{
    // 	if(disputeType === 'active'){
    // 		setDiputes(disputesActiveRespondent);
    // 	}
    // 	else{
    // 		setDiputes(disputesResolvedRespondent);
    // 	}
    // }
    if (
      profile.profile_type == "arbitrator_rv" ||
      profile.profile_type == "mediator" ||
      profile.profile_type == "witness"
    ) {
      if (disputeType === "active") {
        setDiputes(disputesActiveArbitrator);
      } else {
        setDiputes(disputesResolvedArbitrator);
      }
    } else {
      if (disputeType === "active") {
        setDiputes([...disputesActiveClaimant, ...disputesActiveRespondent]);
      } else {
        setDiputes([
          ...disputesResolvedClaimant,
          ...disputesResolvedRespondent,
        ]);
      }
    }
  }, [disputeType]);

  // const handleClickOnRoleTab = (tab) => {
  // 	setRole(tab);
  // }

  const handleClickOnDisputeTypeTab = (tab) => {
    setDisputeType(tab);
  };

  return (
    <>
      <Box>
				<Box height="2.5rem" />
				<div className={classes.header} style={isTiac ? {justifyContent:'flex-end'}:null}>
					{!isTiac && (
            <Typography variant="h1">
              {`Hi, ${profile.user.first_name}`}
            </Typography>
          )}
					{/* <Tabs
						tabs={roleTabs}
						handleClickTab={handleClickOnRoleTab}
						choice={role}
					/> */}
          <Tabs
            tabs={disputeTypeTabs}
            handleClickTab={handleClickOnDisputeTypeTab}
            choice={disputeType}
          />
        </div>
        <Box height="1.5rem" />
        {disputes?.map((dispute,index) => {
          let disputeName = dispute?.name.split("v.");
          if (disputeName.length === 1) {
            disputeName = dispute?.name.split("vs");
          }
          return (
            <React.Fragment key={dispute.id+disputes.length-index}>
              <Grid container justify="space-between">
                <Grid item xs={9}>
                  <Typography className={classes.caseName}>
                    {disputeName[0]}
                    <span
                      style={{
                        color: "#898989",
                        fontFamily: "Satoshi-Regular",
                      }}
                    >
                      vs
                    </span>
                    {disputeName[1]}
                  </Typography>
                  <Typography  style={{color: "#898989",fontFamily: "Satoshi-Regular", fontSize: '1rem'}}>{dispute?.tiac_custom_dispute_id}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    className={classes.blue}
                    onClick={() => {
                      history.push(`disputes/${dispute.id}`);
                    }}
                  >
                    VIEW
                  </Typography>
                </Grid>
              </Grid>
              <Box height="1rem" />
            </React.Fragment>
          );
        })}
      </Box>
    </>
  );
}
