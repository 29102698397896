import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { Delete, KeyboardBackspace } from "@material-ui/icons";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import GeneralSebiActivities from "Admin/Sebi/GeneralSebiActivities";
import instance from "api/globalaxios";
import { format } from "date-fns";
import { getTempDetails } from "features/TempTiacForm/ClaimantPage/questions";
import { GetAnswersTiac } from "features/TempTiacForm/Tiacfinal/final";
import { getQuestionsTiac } from "features/TempTiacForm/UploadPage/questions";
import deleteSvg from "images/delete.svg";
import FileImage from "images/file.svg";
import FolderImage from "images/folder.svg";
import { DropzoneDialog } from "material-ui-dropzone";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";
import {
  isArb,
  isArbitrationConference,
  isDifc,
  isSebi,
  isTempFormV2Tiac,
  isTiac,
  isKilumaya
} from "Routes/Routes";
import { downloadLegalDoc, tiacdoc, updateDispute } from "slices/disputeSlice";
import { formatDate } from "utils";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "2rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: "1.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "1rem",
    },
  },
  btntext: {
    color: theme.palette.primary.main,
    fontFamily: "Satoshi-Bold",
    // lineHeight: "21px",
    textTransform: "none",
    // [theme.breakpoints.up("md")]: {
    //   lineHeight: "22px",
    // },
    // [theme.breakpoints.up("lg")]: {
    //   lineHeight: "24px",
    // },
  },
  paperPartiesInvolved: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "3rem",
    [theme.breakpoints.down("md")]: {
      padding: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
    },
  },
  PartiesInvolvedHeading: {
    color: "#333333",
    fontSize: "14px",
    fontWeight: "600",
    fontFamily: `'Inter',sans-serif`,
    marginBottom: "1rem",
  },
  PartiesInvolvedText: {
    color: "#4F4F4F",
    fontSize: "14px",
    fontWeight: "500",
    fontFamily: `'Inter',sans-serif`,
    marginBottom: "0.6rem",
  },
  paperDisputeDetails: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "3rem",
    [theme.breakpoints.down("md")]: {
      padding: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
    },
  },
  disputeNameHeading: {
    color: "#4F4F4F",
    fontSize: "24px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
  },
  text: {
    color: "#4F4F4F",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
  },
  bluetext: {
    color: "#2F80ED",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
  },
  btnTextWhite: {
    color: "#fff",
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    textTransform: "none",
  },
  btnTextBlack: {
    color: "#4F4F4F",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: `'Inter',sans-serif`,
    textTransform: "none",
  },
  generate: {
    fontSize: "18px",
    color: `${theme.palette.primary.main}`,
    textTransform: "none",
    textDecoration: "underline",
  },
  activityText: {
    fontSize: "28px",
    fontWeight: 400,
    fontFamily: `'Inter',sans-serif`,
    color: "#4F4F4F",
    marginLeft: "2rem",
  },
  activityPaper: {
    border: "1px solid #BDBDBD",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
    padding: "2rem 2rem 1.3rem 2rem",
  },
  activityUsername: {
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: `'Inter',sans-serif`,
    color: "#2F80ED",
  },
  activityheading: {
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: `'Inter',sans-serif`,
    color: "#333333",
    wordBreak: "break-word",
  },
  activityTime: {
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: `'Inter',sans-serif`,
    color: "#333333",
    wordBreak: "break-word",
  },
  activityfileFolderName: {
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: `'Inter',sans-serif`,
    color: "#000000",
    wordBreak: "break-word",
  },
  decision: {
    fontSize: "16px",
    fontWeight: 400,
    color: "#4F4F4F",
    marginLeft: "2rem",
    wordBreak: "break-all",
  },
  decisionDate: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#4F4F4F",
    marginRight: "2rem",
  },
  decisionTableHeading: {
    fontSize: '1.1rem',
    fontFamily: 'Satoshi-Bold',
  },
  hov: {
    "&:hover": {
      color: "#333333",
    },
  },
  alignRight: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "0.5rem",
  },
  boldText: {
    fontFamily: "Satoshi-Bold",
  },
}));

export default function SingleDisputeRvTiac({
  profile,
  activeDispute,
  activeCase,
  activities,
  allProfiles,
  reload,
  hearingDate,
  disputeId
}) {
  // useBodyBackgroundChange(backgrounds.LIGHTBLUE);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [activitiesSingle, setActivitiesSingle] = useState([]);

  const [tempDispute, setTempDispute] = useState();
  useEffect(() => {
    if (isTiac || isArb)
      getTempDetails(activeDispute.id)
        .then((res) => {
          if (res.data && res.data.id) {
            setTempDispute(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
          console.log(err.request.status);
          console.log(err.request.response);
        });
  }, [activeDispute]);

  const [questionRadio, setQuestionRadio] = useState();
  const [answerRadio, setanswerRadio] = useState();
  React.useEffect(() => {
    if (profile.id == activeDispute.created_by) {
      getQuestionsTiac(profile.user.email).then(
        (res) => {
          setQuestionRadio(
            res.data.results.find((item) => item.display_order_id == 51)
          );
        },
        (error) => {
          console.log("err", error);
        }
      );
    }
  }, [activeDispute, profile.id, profile.user.email]);
  React.useEffect(() => {
    if (questionRadio)
      GetAnswersTiac(profile.user.email, activeDispute.id).then((res) => {
        let t = res.data.results.find(
          (item) => item.question == questionRadio.id
        );
        setanswerRadio(t);
      });
  }, [questionRadio, profile, activeDispute]);

  const isRegistrationCompleted = activeDispute.approved;
  const [isFormCompleted, setisFormCompleted] = useState(
    activeDispute.tiac_dispute_step == "form_complete"
  );
  React.useEffect(() => {
    if (
      tempDispute &&
      activeDispute.tiac_dispute_step == "additional_info_complete"
    ) {
      setisFormCompleted(true);
    }
  }, [activeDispute.tiac_dispute_step, tempDispute]);

  // const isFormCompleted = activeDispute.tiac_dispute_step == "form_complete";
  const isFormButtonEnabled = activeDispute.created_by == profile.id;
  const isPaymentCompleted =
    activeDispute.rv_tiac_payment_status &&
    activeDispute.rv_tiac_payment_status == "complete"
      ? true
      : false;
  const isPaymentButtonEnabled = activeDispute.created_by == profile.id;
  const decisionFiles = activeDispute.all_docs.filter(
    (item) => !item.is_rfa_rv
  );
  const sharedAwards = activeDispute.all_docs.filter(
    (item) => !item.is_rfa_rv && item.is_final
  );
  const sharedPartialAwards = activeDispute.all_docs.filter(
    (item) =>
      !item.is_rfa_rv && item.is_final && item.award_type == "partial_award"
  );
  const sharedFinalAwards = activeDispute.all_docs.find(
    (item) =>
      !item.is_rfa_rv && item.is_final && item.award_type == "final_award"
  );

  const [currentDecision, setCurrentDecision] = useState();
  const [open, setOpen] = useState(false);
  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
    setCurrentDecision();
  };

  const [openAwardType, setOpenAwardType] = useState(false);
  const openModalAwardType = () => {
    setOpenAwardType(true);
  };
  const closeModalAwardType = () => {
    setOpenAwardType(false);
    setCurrentDecision();
  };

  const [openUpload, setOpenUpload] = React.useState(false);
  const [files, setFiles] = React.useState([]);

  const addFile = (file) => {
    files.push(file);
    setFiles(files);
  };
  const deleteFile = (index) => {
    let localFiles = [...files];
    localFiles.splice(index, 1);
    setFiles(localFiles);
  };

  const handleCompleteRequestForm = () => {
    localStorage.setItem("dispute_id", activeDispute.id);
    localStorage.setItem("email", profile.user.email);
    localStorage.setItem(
      "full_name",
      profile.user.first_name + " " + profile.user.last_name
    );
    localStorage.setItem("phone_number", profile.phone_number);
    localStorage.removeItem("arbi_step");
    localStorage.removeItem("activestep");
    if (!tempDispute) {
      if (profile.profile_type == "general_rv") {
        if (!activeDispute.rv_claimant_lawyer_step_complete) {
          history.push("/tiac_claimant_lawyer");
        } else if (!activeDispute.rv_respondent_choose_step_complete) {
          history.push("/tiac_respondent");
        } else if (activeDispute.tiac_dispute_step == "upload_complete") {
          history.push("/tiac_arbitrator");
        } else if (
          activeDispute.tiac_dispute_step == "arbitrator_complete" ||
          activeDispute.tiac_dispute_step == "additional_info_complete"
        ) {
          history.push("/tiac_additionalinfo");
        } else if (activeCase.lawyer_rv.length < 1) {
          history.push("/tiac_respondent_lawyer");
        } else {
          history.push("/tiac_upload");
        }
      } else {
        if (!activeDispute.rv_claimant_choose_step_complete) {
          history.push("/tiac_claimant");
        } else if (!activeDispute.rv_respondent_choose_step_complete) {
          history.push("/tiac_respondent");
        } else if (activeDispute.tiac_dispute_step == "upload_complete") {
          history.push("/tiac_arbitrator");
        } else if (
          activeDispute.tiac_dispute_step == "arbitrator_complete" ||
          activeDispute.tiac_dispute_step == "additional_info_complete"
        ) {
          history.push("/tiac_additionalinfo");
        } else if (activeCase.lawyer_rv.length < 1) {
          history.push("/tiac_respondent_lawyer");
        } else {
          history.push("/tiac_upload");
        }
      }
    } else {
      if (!isTempFormV2Tiac) {
        if (profile.profile_type == "general_rv") {
          if (!activeDispute.rv_claimant_choose_step_complete) {
            history.push("/old_tiac_form");
          } else if (!activeDispute.rv_claimant_lawyer_step_complete) {
            history.push("/tiac_claimant_lawyer");
          } else if (!activeDispute.rv_respondent_choose_step_complete) {
            history.push("/tiac_respondent");
          } else if (activeDispute.tiac_dispute_step == "upload_complete") {
            history.push("/tiac_arbitrator");
          } else if (activeDispute.tiac_dispute_step == "arbitrator_complete") {
            history.push("/tiac_additionalinfo");
          } else if (!activeDispute.rv_respondent_lawyer_step_complete) {
            history.push("/tiac_respondent_lawyer");
          } else {
            history.push("/tiac_upload");
          }
        } else {
          if (!activeDispute.rv_claimant_lawyer_step_complete) {
            history.push("/old_tiac_form");
          } else if (!activeDispute.rv_claimant_choose_step_complete) {
            history.push("/tiac_claimant");
          } else if (!activeDispute.rv_respondent_choose_step_complete) {
            history.push("/tiac_respondent");
          } else if (activeDispute.tiac_dispute_step == "upload_complete") {
            history.push("/tiac_arbitrator");
          } else if (activeDispute.tiac_dispute_step == "arbitrator_complete") {
            history.push("/tiac_additionalinfo");
          } else if (!activeDispute.rv_respondent_lawyer_step_complete) {
            history.push("/tiac_respondent_lawyer");
          } else {
            history.push("/tiac_upload");
          }
        }
      } else {
        history.push("/resumerfa?find_active_step=true");
      }
    }
  };

  const handlePayment = () => {
    dispatch(
      updateDispute({
        id: activeDispute.id,
        rv_tiac_payment_status: "complete",
      })
    ).then(() => {
      reload();
    });
  };

  const [loading, setLoading] = useState(false);

  function uploadDispute(files, activeDispute, type) {
    return async (dispatch) => {
      const data = new FormData();
      files.forEach((file) => {
        data.append(file.name, file);
      });
      try {
        const response = await instance.put(
          "/uploaddispute/" + activeDispute.id + "/?award_type=" + type,
          data,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        );
      } catch (error) {
        console.log(error);
      }
    };
  }

  const uploadDecision = (type) => {
    setLoading(true);
    dispatch(uploadDispute(files, activeDispute, type))
      .then(() => {
        reload();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  async function deleteDecisionId(id) {
    return await instance.delete(`/arbitrator_decisiondelete/${id}`);
  }

  const deleteDecision = () => {
    // delete currentdecision id
    deleteDecisionId(currentDecision.id).then((res) => {
      reload();
    });
  };

  function PartiesInvolved() {
    return (
      <Paper className={classes.paperPartiesInvolved} style={(isSebi||isTiac||isDifc||isArb||isKilumaya)? {padding:'2rem',border:'1px solid #E5E5E5'}:null}>
        <Typography className={classes.PartiesInvolvedHeading} style={(isSebi||isTiac||isDifc||isArb||isKilumaya)? {fontFamily:'Satoshi-Bold',fontSize:'1.2rem',lineHeight:'1.5rem',color:'black'}:null}>
          Parties involved
        </Typography>
        {allProfiles.map((profile) => {
          return (
            <>
              <Grid
                container
                key={profile.profile.id}
                justify="space-between"
                spacing={1}
              >
                <Grid item xs={6}>
                  <Typography className={(isSebi||isTiac||isDifc||isArb||isKilumaya)?classes.boldText:classes.PartiesInvolvedText}>
                    {profile.user.first_name} {profile.user.last_name}
                  </Typography>
                  {(isSebi||isTiac) && (
                    <Typography
                      style={isSebi?{visibility:'hidden'}:{
                        color: "#5A5A5A",
                        fontSize: "0.9rem",
                        lineHeight: "normal",
                      }}
                    >
                      {profile.profile.profile_type === "Arbitrator" ||
                      profile.profile.profile_type === "Conciliator"
                        ? "---"
                        : profile.user.email}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={6} style={(isSebi||isTiac||isDifc||isArb||isKilumaya)?{textAlign:'right'}:null}>
                  <Typography className={!(isSebi||isTiac||isDifc||isArb||isKilumaya)?classes.PartiesInvolvedText:null}>
                    {profile.profile.profile_type}
                  </Typography>
                </Grid>
              </Grid>
              <Box height="0.8rem" />
            </>
          );
        })}
      </Paper>
    );
  }

  function DisputeDetails() {
    return (
      <Paper className={classes.paperDisputeDetails} style={(isSebi||isTiac||isDifc||isArb||isKilumaya)? {padding:'2rem',border:'1px solid #E5E5E5'}:null}>
        <Typography className={classes.disputeNameHeading} style={(isSebi||isTiac||isDifc||isArb||isKilumaya)? {fontFamily:'Satoshi-Bold',fontSize:'1.2rem',lineHeight:'1.5rem',color:'black'}:null}>
          {activeDispute.name}
        </Typography>
        <Box height={(isSebi||isTiac||isDifc||isArb||isKilumaya)?"1.2rem":"2.5rem"} />

        <Typography className={(isSebi||isTiac||isDifc||isArb||isKilumaya)?classes.alignRight:null}>
          <span className={!(isSebi||isTiac||isDifc||isArb||isKilumaya)?classes.bluetext:null}>Case ID </span>
          <span className={!(isSebi||isTiac||isDifc||isArb||isKilumaya)?classes.text:null}>
            {isDifc || isArbitrationConference
              ? activeDispute.id
              : activeDispute.approved
              ? activeDispute.tiac_custom_dispute_id
              : "---"}
          </span>
        </Typography>
        {isSebi && (
          <Typography className={classes.alignRight}>
            <span>Stage </span>
            <span>
              {activeDispute.flow_type === "mediation"
                ? "Conciliation"
                : "Arbitration"}
            </span>
          </Typography>
        )}
        {isSebi && (
          <Typography className={classes.alignRight}>
            <span>MII </span>
            <span>{activeDispute.description}</span>
          </Typography>
        )}
        {!isDifc && !isArbitrationConference && !isSebi && (
          <Typography className={(isTiac||isArb||isKilumaya)?classes.alignRight:null}>
            <span className={!(isTiac||isArb||isKilumaya)?classes.bluetext:null}>Date Of Submission </span>
            <span className={!(isTiac||isArb||isKilumaya)?classes.text:null}>
              {formatDate(activeDispute.created)}
            </span>
          </Typography>
        )}
        {!isDifc && !isArbitrationConference && (
          <Typography className={(isSebi||isTiac||isArb||isKilumaya)?classes.alignRight:null}>
            <span className={!(isSebi||isTiac||isArb||isKilumaya)?classes.bluetext:null}>Registration Date </span>
            <span className={!(isSebi||isTiac||isArb||isKilumaya)?classes.text:null}>
              {isRegistrationCompleted
                ? formatDate(activeDispute.approved_date)
                : isFormCompleted
                ? "Awaiting Registration"
                : "---"}
            </span>
          </Typography>
        )}
        <Typography className={(isSebi||isTiac||isDifc||isArb||isKilumaya)?classes.alignRight:null}>
          <span className={!(isSebi||isTiac||isDifc||isArb||isKilumaya)?classes.bluetext:null}>Hearing Date </span>
          <span className={!(isSebi||isTiac||isDifc||isArb||isKilumaya)?classes.text:null}>{hearingDate}</span>
        </Typography>
        {!isDifc && !isArbitrationConference && !isSebi && (
          <Typography className={(isSebi||isTiac||isArb||isKilumaya)?classes.alignRight:null}>
            <span className={!(isSebi||isTiac||isArb||isKilumaya)?classes.bluetext:null}>
              {!isSebi ? "Award" : "Settlement Agreement / Award"}{" "}
            </span>
            {!isRegistrationCompleted && (
              <span className={!(isSebi||isTiac||isArb||isKilumaya)?classes.text:null}>{`---`}</span>
            )}
            {isRegistrationCompleted &&
              (!sharedAwards.length > 0 ? (
                <span className={!(isSebi||isTiac||isArb||isKilumaya)?classes.text:null}>{`(In Progress)`}</span>
              ) : (
                <>
                  {sharedPartialAwards.map((item, index) => {
                    return (
                      <span
                        key={item.id}
                        className={!(isSebi||isTiac||isArb||isKilumaya)?classes.bluetext:null}
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          downloadLegalDoc(item.id);
                        }}
                      >
                        {sharedPartialAwards.length == index + 1 &&
                        !sharedFinalAwards
                          ? `Partial Award(${formatDate(item.modified)})`
                          : `Partial Award(${formatDate(item.modified)}), `}
                      </span>
                    );
                  })}
                  {sharedFinalAwards && (
                    <span
                      className={!(isSebi||isTiac||isArb||isKilumaya)?classes.bluetext:null}
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={() => {
                        downloadLegalDoc(sharedFinalAwards.id);
                      }}
                    >
                      Final Award
                    </span>
                  )}
                </>
              ))}
          </Typography>
        )}
        <Box height="2rem" />
        {(!isRegistrationCompleted ||
          !isFormCompleted ||
          !isPaymentCompleted) &&
        !isSebi ? (
          <Grid container direction="row" spacing={2}>
            <Grid item md={9} xs={12}>
              {isFormCompleted || !isFormButtonEnabled ? (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: "100%" }}
                  disabled={true}
                >
                  Complete Request Form
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: "100%" }}
                  onClick={handleCompleteRequestForm}
                  disabled={isFormCompleted}
                >
                  Complete Request Form
                </Button>
              )}
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid container spacing={3}>
              <Grid item style={(isSebi||isDifc) ? { width: "50%" } : null}>
                <Button
                  variant="outlined"
                  color={(isSebi||isTiac||isDifc||isArb||isKilumaya)?'primary':'default'}
                  style={(isSebi||isDifc)?{width:'100%'}:{ width: "100px" }}
                  onClick={() => {
                    history.push(`/events`);
                  }}
                >
                  <Typography className={(isSebi||isTiac||isDifc||isArb||isKilumaya)?classes.boldText:classes.btnTextBlack} >
                    Calendar
                  </Typography>
                </Button>
              </Grid>
              <Grid item style={(isSebi||isDifc) ? { width: "50%" } : null}>
                <Button
                  variant="outlined"
                  color={(isSebi||isTiac||isDifc||isArb||isKilumaya)?'primary':'default'}
                  style={(isSebi||isDifc)?{width:'100%'}:{ width: "100px" }}
                  onClick={() => {
                    history.push(`/disputes/${activeDispute.id}/files/0`);
                  }}
                >
                  <Typography className={(isSebi||isTiac||isDifc||isArb||isKilumaya)?classes.boldText:classes.btnTextBlack} >
                    Files
                  </Typography>
                </Button>
              </Grid>
            </Grid>
            <Box height="1rem" />
            <Grid container direction="row" spacing={2}>
              <Grid item style={(isSebi||isDifc) ? { width: "100%" } : null}>
                <Button
                  variant="contained"
                  color={(isSebi||isTiac||isDifc||isArb||isKilumaya)?'primary':'default'}
                  style={!(isSebi||isDifc)?((isTiac||isArb)||isKilumaya)?{width: "224px"}:{ width: "224px", background: "#2F80ED" }:{width:'100%'}}
                  onClick={() => {
                    history.push(`/disputes/${activeDispute.id}/chat`);
                  }}
                >
                  <Typography className={(isSebi||isTiac||isDifc||isArb||isKilumaya)?classes.boldText:classes.btnTextWhite} style={{textTransform: 'none'}} >
                    {isDifc ? "Go to Meeting Rooms" : "Go to Hearing Rooms"}
                  </Typography>
                </Button>
              </Grid>
              {!isDifc &&
                !isArbitrationConference &&
                !isSebi &&
                (profile?.profile_type === "arbitrator_rv" || isArb || isKilumaya) && (
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ border: "none" }}
                      onClick={() => {
                        tiacdoc(
                          activeDispute.id,
                          activeDispute.tiac_custom_dispute_id
                        );
                      }}
                    >
                      <Typography className={classes.generate}>
                        Generate
                      </Typography>
                    </Button>
                  </Grid>
                )}
            </Grid>
          </>
        )}
      </Paper>
    );
  }

  function bytesToSize(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }

  const renderFiles = (files, deleteFile) => {
    return files.length !== 0
      ? files.map((file, index) => {
          return (
            <Box className={classes.itempaper}>
              <Box
                textAlign="center"
                position="relative"
                key={index}
                // display="flex"
                // justifyContent="center"
              >
                <Box>
                  <Typography
                    style={{ wordBreak: "break-all", paddingTop: "20px" }}
                    variant="subtitle2"
                  >
                    {file.name}
                  </Typography>
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    onClick={() => {
                      deleteFile(index);
                    }}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          );
        })
      : null;
  };

  return (
    <Grid container justify="center">
      <Grid item lg={10} md={10} sm={11} xs={11} className={classes.root}>
        <Button
          // startIcon={<KeyboardArrowLeftIcon style={{ color: "#2F80ED" }} />}
          disableElevation
          disableFocusRipple
          disableRipple
          disableTouchRipple
          style={{ backgroundColor: "transparent", cursor: "default" }}
        >
          <Grid container spacing={1}>
            <Grid item>
              <Typography
                className={classes.btntext}
                style={{ color: "black" }}
                onClick={() => {
                  history.goBack();
                }}
              >
                <KeyboardBackspace />
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={`${classes.btntext} ${classes.hov}`}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  history.push("/");
                }}
              >
                Dashboard
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={classes.btntext}
                style={{ color: "black" }}
              >
                /
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={`${classes.btntext} ${classes.hov}`}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  history.push(`/disputes/${activeDispute.id}/`);
                }}
              >
                {activeDispute.name}
              </Typography>
            </Grid>
          </Grid>
        </Button>
        <Grid container spacing={2}>
          <Grid item lg={(isSebi||isTiac||isDifc||isArb||isKilumaya)?6:5} md={6} sm={6} xs={12} style={{ display: "flex" }}>
            <DisputeDetails />
          </Grid>
          {!isArbitrationConference && (
            <Grid item lg={(isSebi||isTiac||isDifc||isArb||isKilumaya)?6:5} md={6} sm={6} xs={12} style={{ display: "flex" }}>
              <PartiesInvolved />
            </Grid>
          )}
        </Grid>
        <Box height="4rem" />
        <Grid container>
          <Grid item lg={(isSebi||isTiac||isDifc||isArb||isKilumaya)?12:10} md={12} sm={12} xs={12}>
            {isRegistrationCompleted &&
              isFormCompleted &&
              (isSebi || isPaymentCompleted) && (
                <>
                  {(profile.profile_type == "arbitrator_rv" ||
                    profile.profile_type == "mediator") &&
                    !isDifc &&
                    !isArbitrationConference &&
                    !isSebi && (
                      <>
                        <Typography style={{fontFamily:'Satoshi-Bold',fontSize:'1.5rem'}}>
                          {!isSebi ? "Award" : "Settlement Agreement / Award"}
                        </Typography>
                        <Box style={{ padding: "1rem" }}>
                          <Grid container spacing={2}>
                            <Grid item sm={7} xs={5}>
                              <Typography className={classes.decisionTableHeading}>
                                Document Name
                              </Typography>
                            </Grid>
                            <Grid item sm={2} xs={3}>
                              <Typography className={classes.decisionTableHeading}>
                                Type
                              </Typography>
                            </Grid>
                            <Grid item sm={2} xs={3}>
                              <Typography className={classes.decisionTableHeading}>
                                Upload Date
                              </Typography>
                            </Grid>
                            <Grid item sm={1} xs={1}></Grid>
                          </Grid>
                        </Box>
                        <Paper container style={{ background: 'white', border: '1px solid #E5E5E5' }}>
                          {decisionFiles.map((decision) => {
                            return (
                              <Box style={{padding:'8px 1rem', borderBottom: '1px solid #E5E5E5'}} key={decision.id}>
                                <Grid
                                  container
                                  spacing={2}
                                  alignItems="center"
                                >
                                  <Grid item sm={7} xs={5}>
                                    <Typography
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        downloadLegalDoc(decision.id);
                                      }}
                                    >
                                      {decision.name}
                                    </Typography>
                                  </Grid>
                                  <Grid item sm={2} xs={3}>
                                    <Typography >
                                      {decision.award_type == "partial_award"
                                        ? "Partial"
                                        : "Final"}
                                    </Typography>
                                  </Grid>
                                  <Grid item sm={2} xs={3}>
                                    <Typography >
                                      {formatDate(decision.created)}
                                    </Typography>
                                  </Grid>
                                  <Grid item sm={1} xs={1}>
                                    {/* <img
                                      src={deleteSvg}
                                      alt="delete"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setCurrentDecision(decision);
                                        openModal();
                                      }}
                                    /> */}
                                    <Delete
                                      style={{ cursor: "pointer", color:'#4F4F4F' }}
                                      onClick={() => {
                                        setCurrentDecision(decision);
                                        openModal();
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Box>
                            );
                          })}
                          {files.length === 0 &&
                            activeDispute.status != "closed" && (
                              <Grid container justify="center">
                                <Grid item>
                                  <IconButton
                                    style={{padding:'1rem'}}
                                    color="primary"
                                    onClick={() => {
                                      setOpenUpload(true);
                                    }}
                                  >
                                    <AddCircleOutlineIcon />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            )}
                          {renderFiles(files, deleteFile)}
                        </Paper>
                        <Box height="1rem" />
                        <Grid container justify="center">
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ width: "224px" }}
                              onClick={() => {
                                openModalAwardType();
                              }}
                              disabled={
                                files.length < 1 ||
                                loading ||
                                activeDispute.status == "closed"
                              }
                            >
                              SUBMIT
                            </Button>
                          </Grid>
                        </Grid>
                        <Box height="2rem" />
                      </>
                    )}
                  {/* {(isSebi || isTiac) ? ( */}
                    <GeneralSebiActivities activities={activitiesSingle} setActivities={setActivitiesSingle} isGetActivityByTabAndId={true} disputeId={disputeId} activityTitle="Case Activity" />
                  {/* ) : (
                    <>
                      <Box height="2rem" />
                      <Typography className={classes.activityText}>
                        Activity
                      </Typography>
                      <Box height="1rem" />
                      {activities.map((item) => {
                        let isFolder = !item.is_for_file;
                        return (
                          <React.Fragment key={item.id}>
                            <Paper className={classes.activityPaper}>
                              <Typography
                                className={classes.activityUsername}
                                display="inline"
                              >
                                {`${item.uploaded_by.first_name} ${item.uploaded_by.last_name} `}
                              </Typography>
                              <Typography
                                className={classes.activityheading}
                                display="inline"
                              >
                                {item.activity_name}
                              </Typography>
                              <Typography className={classes.activityTime}>
                                {format(
                                  new Date(item.created),
                                  "d MMMM y, h:mm a"
                                )}{" "}
                                - {item.dispute.name}
                              </Typography>
                              <Box height="1rem" />
                              <Grid container spacing={1}>
                                <Grid item>
                                  <img
                                    src={isFolder ? FolderImage : FileImage}
                                    alt={
                                      isFolder ? "folder image" : "file image"
                                    }
                                    style={{ marginTop: "4px", height: "40px" }}
                                  />
                                </Grid>
                                <Grid item sm={10} xs={9}>
                                  <Typography
                                    className={classes.activityfileFolderName}
                                  >
                                    {item.file_or_folder_name}
                                  </Typography>
                                  <Typography className={classes.activityTime}>
                                    {bytesToSize(item.size)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Paper>
                            <Box height="1rem" />
                          </React.Fragment>
                        );
                      })}
                    </>
                  )} */}
                </>
              )}
          </Grid>
        </Grid>
      </Grid>

      <DropzoneDialog
        cancelButtonText={"cancel"}
        submitButtonText={"submit"}
        maxFileSize={104857600}
        open={openUpload}
        onClose={() => setOpenUpload(false)}
        onSave={(files) => {
          files.forEach((file) => {
            addFile(file);
          });
          setOpenUpload(false);
        }}
        showPreviews={true}
        filesLimit={1}
        showFileNamesInPreview={true}
        acceptedFiles={[
          "image/jpeg",
          "image/png",
          "image/jpg",
          "application/msword",
          "application/pdf",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ]}
      />

      <Popup
        open={open}
        closeOnDocumentClick
        closeOnEscape
        onClose={closeModal}
        position="center"
        modal
        nested
        contentStyle={{ background: "white", width: "330px", height: "185px" }}
        overlayStyle={{ background: "rgba(51, 51, 51, 0.7)" }}
      >
        <Box style={{ padding: "1rem 1.3rem" }}>
          <Typography
            style={{
              fontSize: "20px",
              color: "#333333",
              "font-weight": "bold",
              "line-height": "23px",
              letterSpacing: "0.0015em",
            }}
          >
            Delete Award?
          </Typography>
          <Box height="0.5rem"></Box>
          <Typography
            style={{
              fontSize: "16px",
              color: "#828282",
              "line-height": "28px",
            }}
          >
            Are you sure you want to delete this Award?
          </Typography>
          <Box height="0.7rem"></Box>
        </Box>
        <Box style={{ padding: "1rem 1.3rem" }}>
          <Grid
            container
            direction="row"
            justify="flex-end"
            style={{ padding: "10px" }}
          >
            <Grid item xs={2}>
              <Typography
                style={{
                  fontSize: "14px",
                  color: "#828282",
                  "line-height": "16px",
                  letterSpacing: "0.02em",
                  "font-weight": "500",
                  cursor: "pointer",
                }}
                onClick={closeModal}
              >
                NO
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                style={{
                  fontSize: "14px",
                  color: "#2F80ED",
                  "line-height": "16px",
                  letterSpacing: "0.02em",
                  "font-weight": "500",
                  cursor: "pointer",
                }}
                onClick={deleteDecision}
              >
                YES
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Popup>

      <Popup
        open={openAwardType}
        closeOnDocumentClick
        closeOnEscape
        onClose={closeModalAwardType}
        position="center"
        modal
        nested
        contentStyle={{ background: "white", width: "330px", height: "185px" }}
        overlayStyle={{ background: "rgba(51, 51, 51, 0.7)" }}
      >
        <Box style={{ padding: "1rem 1.3rem" }}>
          <Typography
            style={{
              fontSize: "20px",
              color: "#333333",
              "font-weight": "bold",
              "line-height": "23px",
              letterSpacing: "0.0015em",
            }}
          >
            Upload Award?
          </Typography>
          <Box height="0.5rem"></Box>
          <Typography
            style={{
              fontSize: "16px",
              color: "#828282",
              "line-height": "28px",
            }}
          >
            Is this Award a Partial Award or Final Award
          </Typography>
          <Box height="0.7rem"></Box>
        </Box>
        <Box style={{ padding: "1rem 1.3rem" }}>
          <Grid
            container
            direction="row"
            justify="flex-end"
            style={{ padding: "10px" }}
          >
            <Grid item xs={3}>
              <Typography
                style={{
                  fontSize: "14px",
                  color: "#828282",
                  "line-height": "16px",
                  letterSpacing: "0.02em",
                  "font-weight": "500",
                  cursor: "pointer",
                }}
                onClick={() => {
                  uploadDecision("partial_award");
                }}
              >
                PARTIAL
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                style={{
                  fontSize: "14px",
                  color: "#2F80ED",
                  "line-height": "16px",
                  letterSpacing: "0.02em",
                  "font-weight": "500",
                  cursor: "pointer",
                  textAlign: "right",
                }}
                onClick={() => {
                  uploadDecision("final_award");
                }}
              >
                FINAL
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Popup>
    </Grid>
  );
}
