import { isDev } from "Routes/Routes";
import { websocketUrl } from "api/globalaxios";
import { backgrounds } from "contexts/BackgroundContext";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import { useBodyBackgroundChange, useWebsocket } from "hooks";
import { Steps } from "intro.js-react";
import "intro.js/introjs.css";
import isEmpty from "lodash/isEmpty";
import React, { createContext, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  getCase,
  getCases,
  getDPCGCCase,
  getDPCGCCases,
} from "slices/casesSlice";
import {
  createChatGroup,
  createChatGroupfordev,
  createDPCCChatGroup,
  createRvChatGroup,
  createRvChatGroupfordev,
} from "slices/chatGroupsSlice";
import { getDPCGCDispute, getDispute } from "slices/disputeSlice";
import { getAllDPCGCGroups, getAllGroups, setGroups } from "slices/groupSlice";
import useDeepCompareEffect from "use-deep-compare-effect";
import { getWindowDimension } from "utils/responsiveUtils";
import Chat from "./Chat";
import { subscribeToRoom } from "utils/chatUtils";

// context for ws and container ref
export const containerContext = createContext(null);

const checkRv = (profileType) => {
  const profileArray = profileType.split("_");

  const isRv = profileArray[profileArray.length - 1] === "rv";

  return isRv;
};

export default function ChatContainer() {
  const dispatch = useDispatch();

  let { disputeId } = useParams();

  useBodyBackgroundChange(backgrounds.WHITE);

  const { groups, loading: groupLoading } = useSelector(
    (state) => state.groups
  );

  const { chatgroup, loading: chatGroupLoading } = useSelector(
    (state) => state.chatgroup
  );

  const { activeDispute, loading: disputeLoading } = useSelector(
    (state) => state.disputes
  );

  const profileType = useSelector(
    (state) => state.profile.profile.profile_type
  );

  const [showVC, setShowVC] = useState(false);
  const [vcType, setVcType] = useState("dyte");

  // selected group
  const [selected, setSelected] = useState(0);

  // useDeepCompareEffect(() => {
  //   let selectedGroupIndex = groups.findIndex((group) => {
  //     return group.name === chatgroup.group_name;
  //   });

  //   if (selectedGroupIndex && selectedGroupIndex >= 0) {
  //     setSelected(selectedGroupIndex);
  //   }
  // }, [chatgroup, groups]);

  // Chat messages container
  const messageContainer = React.useRef(null);

  const { connected, ws } = useWebsocket(websocketUrl, messageContainer);

  // responsive screen logic
  const { height, width } = getWindowDimension();
  const [screenWidth, setWidth] = useState(width);

  const handleResize = () => {
    const { height, width } = getWindowDimension();
    setWidth(width);
  };
  let company = sessionStorage.getItem("company");
  useEffect(() => {
    localStorage.setItem('disputeId',disputeId);
    if (company == "dpcgc") {
      dispatch(getDPCGCDispute(disputeId));
      dispatch(getDPCGCCase(disputeId));
      dispatch(getDPCGCCases());
    } else {
      dispatch(getDispute(disputeId));

      dispatch(getCase(disputeId));
      dispatch(getCases());
    }

    // dispatch(getProfile()).then((response) => {

    // }
    if (isDev && checkRv(profileType)) {
      dispatch(createRvChatGroupfordev(disputeId)).then((resp) => {
        dispatch(getAllGroups(disputeId));
      });
    } else if (isDev) {
      dispatch(createChatGroupfordev(disputeId)).then((resp) => {
        dispatch(getAllGroups(disputeId));
      });
    } else if (checkRv(profileType)) {
      dispatch(createRvChatGroup(disputeId)).then((resp) => {
        dispatch(getAllGroups(disputeId));
      });
    } else if (company == "dpcgc") {
      dispatch(createDPCCChatGroup(disputeId)).then((resp) => {
        dispatch(getAllDPCGCGroups(disputeId));
      });
    } else {
      dispatch(createChatGroup(disputeId)).then((resp) => {
        dispatch(getAllGroups(disputeId));
      });
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      dispatch(setGroups([]));
    };
  }, []);

  useEffect(()=>{
    
    if(groups?.length > 0){
      if(selected === 0){
        setSelected(groups[0]._id);
      }
      if(ws.current.readyState === 1){
        groups.forEach((group) => {
          subscribeToRoom(ws, group._id);
        });
      }
    }
  },[groups,ws])

  const hasLoaded = !groupLoading && !disputeLoading && !chatGroupLoading;
  const location = useLocation();
  const profile = useSelector((state) => state.profile, shallowEqual);
  const user = useSelector((state) => state.user.user);
  const [showGuide, setShowGuide] = useState(
    location.pathname.split("/")[1] == "disputes" &&
      location.pathname.split("/")[3] == "chat" &&
      (user.id == 473 || user.id == 214) &&
      !isDev
  );

  const exit = (t) => {
    setShowGuide(false);
  };

  const steps = [
    {
      element: ".hearingroomstep1",
      intro:
        "These are your hearing rooms. These rooms are secure and confidential. If you share a message on one group, it will not reflect in a different group.",
    },
    {
      element: ".hearingroomstep2",
      intro: "Share messages, voice notes, images and videos easily.",
    },
    {
      element: ".hearingroomstep3",
      intro:
        "Schedule a call with the company and we will email them with a notification.",
    },
    {
      element: ".hearingroomstep4",
      intro: "Press this button to start a meeting right now.",
    },
    {
      element: ".hearingroomstep5",
      intro: "Any Video Call hearings recorded by you are stored here.",
    },
    {
      element: ".hearingroomstep6",
      intro:
        "Any Documents and/or images shared by you are stored here to maintain an audit trail.",
    },
  ];

  // console.log('chat container....',groups,chatgroup,selected);

  return (
    <containerContext.Provider
      value={{ messageContainer, ws, showVC, setShowVC, vcType, setVcType }}
    >
      {hasLoaded && !isEmpty(groups) && connected ? (
        <>
          <Steps
            enabled={showGuide}
            steps={steps}
            initialStep={0}
            onExit={exit}
            options={{
              hideNext: false,
              hidePrev: true,
              keyboardNavigation: true,
              exitOnEsc: true,
              exitOnOverlayClick: false,
              showBullets: false,
              showStepNumbers: true,
              disableInteraction: true,
            }}
          />
          <Chat
            screenWidth={screenWidth}
            disputeId={activeDispute.id}
            showVC={showVC}
            chatgroup={chatgroup}
            groups={groups}
            selected={selected}
            setSelected={setSelected}
            profileType={profileType}
          />
        </>
      ) : (
        <CircularCenteredLoader />
      )}
    </containerContext.Provider>
  );
}
