import {
  Box,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@material-ui/core";
import { closed_reason_choices } from "Admin/Sebi/CaseDisplay";
import GeneralSebiActivities from "Admin/Sebi/GeneralSebiActivities";
import instance from "api/globalaxios";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import React, { useEffect, useState } from "react";
import { isSebi } from "Routes/Routes";
import { formatDate } from "utils";
import CaseDisplay from "./CaseDisplay";

const useStyles = makeStyles((theme) => ({
  box: {
    textAlign: "left",
    margin: "1rem 0 0 0",
    [theme.breakpoints.down("xs")]: {
      padding: "0 0.5rem",
    },
  },
  overview: {
    fontSize: "24px",
    lineHeight: "28px",
    fontWeight: "400",
    color: "#252733",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  username: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.2px",
    color: "#252733",
  },
  paper: {
    padding: "1rem 2rem",
    textAlign: "center",
    cursor: "pointer",
    borderRadius: "8px",
    [theme.breakpoints.down("sm")]: {
      padding: "0.5rem 1rem",
    },
    "&:hover": {
      background: "#ebebeb",
    },
  },
  selectedPaper: {
    padding: "1rem 2rem",
    textAlign: "center",
    borderRadius: "8px",
    background: "#BDBDBD",
    [theme.breakpoints.down("sm")]: {
      padding: "0.5rem 1rem",
    },
  },
  headingPaper: {
    fontWeight: "500",
    fontSize: "20px",
    color: "#454545",
    [theme.breakpoints.down("sm")]: {
      fontize: "15px",
    },
  },
  numberPaper: {
    fontWeight: "500",
    fontSize: "40px",
    color: "#2C2C2C",
    [theme.breakpoints.down("sm")]: {
      fontize: "30px",
    },
  },
  activityText: {
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: `'Inter',sans-serif`,
    color: "#4F4F4F",
  },
  activityPaper: {
    border: "1px solid #BDBDBD",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
    padding: "2rem 2rem 1.3rem 2rem",
  },
  activityUsername: {
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: `'Inter',sans-serif`,
    color: "#2F80ED",
  },
  activityheading: {
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: `'Inter',sans-serif`,
    color: "#333333",
    wordBreak: "break-word",
  },
  activityTime: {
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: `'Inter',sans-serif`,
    color: "#333333",
    wordBreak: "break-word",
  },
  activityfileFolderName: {
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: `'Inter',sans-serif`,
    color: "#000000",
    wordBreak: "break-word",
  },
  disputeHeading: {
    fontSize: "12px",
    fontWeight: 700,
    color: "#333333",
  },
  disputeText: {
    fontSize: "16px",
    fontWeight: 400,
    color: "#333333",
    wordBreak: "break-word",
  },
  sortbybtn: {
    cursor: "pointer",
    border: "none",
    background: "#E0E0E0",
    boxShadow:
      "0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)",
    borderRadius: "4px",
  },
  sortbytext: {
    padding: "10px 30px 10px 10px",
    fontSize: "12px",
    lineHeight: "0px",
    textAlign: "center",
    textTransform: "uppercase",
    color: "#454545",
  },
}));

export default function Overview({ profile }) {
  const classes = useStyles();

  let cid = sessionStorage.getItem("tiac_overview_caseid");

  const [openCase, setOpenCase] = useState(cid ? true : false);
  const [caseId, setCaseId] = useState(cid ? cid : null);

  let ch = sessionStorage.getItem("tiac_overview_choice");

  const [choice, setChoice] = useState(ch ? ch : null);
  const [loadingDisputes, setLoadingDisputes] = useState(true);
  // const [loadingActivities, setLoadingActivities] = useState(true);
  const [disputes, setDisputes] = useState([]);
  const [disputesReceived, setDisputesReceived] = useState([]);
  const [disputesRegistered, setDisputesRegistered] = useState([]);
  const [disputesClosed, setDisputesClosed] = useState([]);
  const [activities, setActivities] = useState([]);

  let split_by = "vs";
  if (isSebi) {
    split_by = "v.";
  }

  const reloadOverview = () => {
    getDisputes()
      .then((res) => {
        setDisputesReceived(
          res.data[isSebi ? "Arbitration" : "Requests Received"]
        );
        setDisputesRegistered(
          res.data[isSebi ? "Conciliation" : "Requests Registered"]
        );
        setDisputesClosed(res.data["closed"]);
        if (choice == "received") {
          setDisputes(res.data[isSebi ? "Arbitration" : "Requests Received"]);
        } else if (choice == "registered") {
          setDisputes(
            res.data[isSebi ? "Conciliation" : "Requests Registered"]
          );
        } else if (choice == "closed") {
          setDisputes(res.data["closed"]);
        }
        setLoadingDisputes(false);
      })
      .catch((err) => {
        console.log(err);
      });
    // dispatch(getActivities())
    //   .then((res) => {
    //     setActivities(res);
    //     setLoadingActivities(false);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  useEffect(() => {
    reloadOverview();
  }, []);

  async function getDisputes() {
    return await instance.get("/tiac_admin_disputes/");
  }

  function bytesToSize(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }

  const [sortby, setSortby] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleIdSort() {
    setSortby("id");
    setAnchorEl(null);
    let result = [...disputesReceived];
    result.sort(function (a, b) {
      var nameA = a.id;
      var nameB = b.id;
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    setDisputesReceived(result);
    if (choice == "received") setDisputes(result);
    result = [...disputesRegistered];
    result.sort(function (a, b) {
      var nameA = a.id;
      var nameB = b.id;
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    setDisputesRegistered(result);
    if (choice == "registered") setDisputes(result);
    result = [...disputesClosed];
    result.sort(function (a, b) {
      var nameA = a.id;
      var nameB = b.id;
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    setDisputesClosed(result);
    if (choice == "closed") setDisputes(result);
  }
  function handleCNameSort() {
    setSortby("cname");
    setAnchorEl(null);
    let result = [...disputesReceived];
    result.sort(function (a, b) {
      var nameA = a.name.split(split_by)[0].trim().toUpperCase();
      var nameB = b.name.split(split_by)[0].trim().toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    setDisputesReceived(result);
    if (choice == "received") setDisputes(result);
    result = [...disputesRegistered];
    result.sort(function (a, b) {
      var nameA = a.name.split(split_by)[0].trim().toUpperCase();
      var nameB = b.name.split(split_by)[0].trim().toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    setDisputesRegistered(result);
    if (choice == "registered") setDisputes(result);
    result = [...disputesClosed];
    result.sort(function (a, b) {
      var nameA = a.name.split(split_by)[0].trim().toUpperCase();
      var nameB = b.name.split(split_by)[0].trim().toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    setDisputesClosed(result);
    if (choice == "closed") setDisputes(result);
  }
  function handleRNameSort() {
    setSortby("rname");
    setAnchorEl(null);
    let result = [...disputesReceived];
    result.sort(function (a, b) {
      var nameA = a.name.split(split_by)[1].trim().toUpperCase();
      var nameB = b.name.split(split_by)[1].trim().toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    setDisputesReceived(result);
    if (choice == "received") setDisputes(result);
    result = [...disputesRegistered];
    result.sort(function (a, b) {
      var nameA = a.name.split(split_by)[1].trim().toUpperCase();
      var nameB = b.name.split(split_by)[1].trim().toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    setDisputesRegistered(result);
    if (choice == "registered") setDisputes(result);
    result = [...disputesClosed];
    result.sort(function (a, b) {
      var nameA = a.name.split(split_by)[1].trim().toUpperCase();
      var nameB = b.name.split(split_by)[1].trim().toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    setDisputesClosed(result);
    if (choice == "closed") setDisputes(result);
  }

  if (openCase) {
    return (
      <CaseDisplay
        setOpenCase={setOpenCase}
        caseId={caseId}
        mode={"RV"}
        profile={profile}
        reloadOverview={reloadOverview}
      />
    );
  }

  if (loadingDisputes) {
    return <CircularCenteredLoader />;
  } else {
    return (
      <>
        <Grid container justify="center">
          <Grid item md={10} sm={11} xs={12}>
            <Box className={classes.box}>
              <Grid
                container
                justify="space-between"
                direction="row"
                style={{ padding: "10px" }}
              >
                <Grid item>
                  <Typography className={classes.overview}>
                    Overview (Open cases)
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.username}>
                    {profile.user.first_name} {profile.user.last_name}
                  </Typography>
                </Grid>
              </Grid>
              <Box height="2rem" />
              {choice && (
                <Grid container justify="flex-end">
                  <Grid item>
                    <button
                      type="button"
                      className={classes.sortbybtn}
                      onClick={handleClick}
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                    >
                      <Typography className={classes.sortbytext}>
                        Sort by
                      </Typography>
                    </button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <MenuItem
                        onClick={handleIdSort}
                        style={sortby == "id" ? { background: "#BDBDBD" } : {}}
                      >
                        ID
                      </MenuItem>
                      <MenuItem
                        onClick={handleCNameSort}
                        style={
                          sortby == "cname" ? { background: "#BDBDBD" } : {}
                        }
                      >
                        Complainant Name
                      </MenuItem>
                      <MenuItem
                        onClick={handleRNameSort}
                        style={
                          sortby == "rname" ? { background: "#BDBDBD" } : {}
                        }
                      >
                        Respondent Name
                      </MenuItem>
                    </Menu>
                  </Grid>
                </Grid>
              )}
              <Box height="1rem" />
              <Grid container spacing={3}>
                {!isSebi ? (
                  <>
                    <Grid item sm={4} xs={6}>
                      <Paper
                        className={
                          choice == "received"
                            ? classes.selectedPaper
                            : classes.paper
                        }
                        onClick={() => {
                          setChoice("received");
                          sessionStorage.setItem(
                            "tiac_overview_choice",
                            "received"
                          );
                          setDisputes(disputesReceived);
                        }}
                      >
                        <Typography className={classes.headingPaper}>
                          Requests Received
                        </Typography>
                        <Typography className={classes.numberPaper}>
                          {disputesReceived.length}
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item sm={4} xs={6}>
                      <Paper
                        className={
                          choice == "registered"
                            ? classes.selectedPaper
                            : classes.paper
                        }
                        onClick={() => {
                          setChoice("registered");
                          sessionStorage.setItem(
                            "tiac_overview_choice",
                            "registered"
                          );
                          setDisputes(disputesRegistered);
                        }}
                      >
                        <Typography className={classes.headingPaper}>
                          Requests Registered
                        </Typography>
                        <Typography className={classes.numberPaper}>
                          {disputesRegistered.length}
                        </Typography>
                      </Paper>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item sm={4} xs={6}>
                      <Paper
                        className={
                          choice == "registered"
                            ? classes.selectedPaper
                            : classes.paper
                        }
                        onClick={() => {
                          setChoice("registered");
                          sessionStorage.setItem(
                            "tiac_overview_choice",
                            "registered"
                          );
                          setDisputes(disputesRegistered);
                        }}
                      >
                        <Typography className={classes.headingPaper}>
                          Conciliation
                        </Typography>
                        <Typography className={classes.numberPaper}>
                          {disputesRegistered.length}
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item sm={4} xs={6}>
                      <Paper
                        className={
                          choice == "received"
                            ? classes.selectedPaper
                            : classes.paper
                        }
                        onClick={() => {
                          setChoice("received");
                          sessionStorage.setItem(
                            "tiac_overview_choice",
                            "received"
                          );
                          setDisputes(disputesReceived);
                        }}
                      >
                        <Typography className={classes.headingPaper}>
                          Arbitration
                        </Typography>
                        <Typography className={classes.numberPaper}>
                          {disputesReceived.length}
                        </Typography>
                      </Paper>
                    </Grid>
                  </>
                )}
                <Grid item sm={4} xs={6}>
                  <Paper
                    className={
                      choice == "closed" ? classes.selectedPaper : classes.paper
                    }
                    onClick={() => {
                      setChoice("closed");
                      sessionStorage.setItem("tiac_overview_choice", "closed");
                      setDisputes(disputesClosed);
                    }}
                  >
                    <Typography className={classes.headingPaper}>
                      Closed
                    </Typography>
                    <Typography className={classes.numberPaper}>
                      {disputesClosed.length}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
              <Box height="2rem" />
              {choice ? (
                <Grid container>
                  <Grid item md={12} sm={12} xs={12}>
                    <Box style={{ padding: "1rem" }}>
                      <Grid container justify="flex-start" spacing={1}>
                        <Grid item sm={2} xs={3}>
                          <Typography className={classes.disputeHeading}>
                            ID
                          </Typography>
                        </Grid>
                        <Grid item sm={3} xs={3}>
                          <Typography cl2assName={classes.disputeHeading}>
                            Claimant Name
                          </Typography>
                        </Grid>
                        <Grid item sm={3} xs={3}>
                          <Typography className={classes.disputeHeading}>
                            Respondent Name
                          </Typography>
                        </Grid>

                        <Grid item sm={2} xs={3}>
                          <Typography className={classes.disputeHeading}>
                            Start Date
                          </Typography>
                        </Grid>
                        {isSebi && choice === "closed" && (
                          <Grid item sm={2} xs={3}>
                            <Typography className={classes.disputeHeading}>
                              Status
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                    <Box height="0.4rem"></Box>
                    {disputes.map((dispute) => {
                      const arr = dispute.name.split(split_by);
                      const complainant = arr[0];
                      const respondent = arr[1];
                      return (
                        <Box key={dispute.id}>
                          <Paper
                            style={{ padding: "1rem", cursor: "pointer" }}
                            onClick={() => {
                              setOpenCase(true);
                              setCaseId(dispute.id);
                              sessionStorage.setItem(
                                "tiac_overview_caseid",
                                dispute.id
                              );
                            }}
                          >
                            <Grid container justify="flex-start" spacing={1}>
                              <Grid item sm={2} xs={3}>
                                <Typography className={classes.disputeText}>
                                  {dispute.approved
                                    ? dispute.tiac_custom_dispute_id
                                    : "---"}
                                </Typography>
                              </Grid>
                              <Grid item sm={3} xs={3}>
                                <Typography className={classes.disputeText}>
                                  {complainant}
                                </Typography>
                              </Grid>
                              <Grid item sm={3} xs={3}>
                                <Typography className={classes.disputeText}>
                                  {respondent}
                                </Typography>
                              </Grid>

                              <Grid item sm={2} xs={3}>
                                <Typography className={classes.disputeText}>
                                  {isSebi && dispute.flow_type === "arbitration"
                                    ? formatDate(
                                        dispute.converted_to_arbitration_at
                                      )
                                    : formatDate(dispute.created)}
                                </Typography>
                              </Grid>
                              {isSebi && choice === "closed" && (
                                <Grid item sm={2} xs={3}>
                                  <Typography className={classes.disputeText}>
                                  {closed_reason_choices.find(item => item.value === dispute.closed_reason).name}
                                  </Typography>
                                </Grid>
                              )}
                            </Grid>
                          </Paper>
                          <Box height="0.6rem"></Box>
                        </Box>
                      );
                    })}
                  </Grid>
                </Grid>
              ) : (
                <>
                  <Grid container>
                    <Grid item md={10} sm={11} xs={12}>
                      {/* {(isSebi||isTiac) ? ( */}
                      <GeneralSebiActivities
                        activities={activities}
                        setActivities={setActivities}
                        activityTitle="Account Activity"
                      />
                      {/* ) : (
                        <>
                          <Grid container>
                            <Grid
                              item
                              style={{
                                padding: "0.4rem 2rem",
                                background: "#E0E0E0",
                              }}
                            >
                              <Typography className={classes.activityText}>
                                Activity
                              </Typography>
                            </Grid>
                          </Grid>
                          <Box height="2rem" />
                          {activities?.map((item) => {
                            let isFolder = !item.is_for_file;
                            return (
                              <React.Fragment key={item.id}>
                                <Paper className={classes.activityPaper}>
                                  <Typography
                                    className={classes.activityUsername}
                                    display="inline"
                                  >
                                    {`${item.uploaded_by.first_name} ${item.uploaded_by.last_name} `}
                                  </Typography>
                                  <Typography
                                    className={classes.activityheading}
                                    display="inline"
                                  >
                                    {item.activity_name}
                                  </Typography>
                                  <Typography className={classes.activityTime}>
                                    {format(
                                      new Date(item.created),
                                      "d MMMM y, h:mm a"
                                    )}{" "}
                                    - {item.dispute.name}
                                  </Typography>
                                  <Box height="1rem" />
                                  <Grid container spacing={1}>
                                    <Grid item>
                                      <img
                                        src={isFolder ? FolderImage : FileImage}
                                        alt={
                                          isFolder ? "folder image" : "file image"
                                        }
                                        style={{
                                          marginTop: "4px",
                                          height: "40px",
                                        }}
                                      />
                                    </Grid>
                                    <Grid item sm={10} xs={9}>
                                      <Typography
                                        className={classes.activityfileFolderName}
                                      >
                                        {item.file_or_folder_name}
                                      </Typography>
                                      <Typography
                                        className={classes.activityTime}
                                      >
                                        {bytesToSize(item.size)}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Paper>
                                <Box height="1rem" />
                              </React.Fragment>
                            );
                          })}
                        </>
                      )} */}
                      <Box height="2rem" />
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }
}
