import {
  Box,
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import CreateProfile from "./CreateProfile";
import React from "react";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: "20px",
    lineHeight: "24px",
    margin: "1rem 0 0 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      lineHeight: "22px",
    },
  },
}));

export default function SetProfile({
  profileType,
  multiple,
  heading,
  setProfile,
  profile,
  profiles,
  setProfiles,
  group,
}) {
  const classes = useStyles();

  return (
    <>
      <Box style={{ textAlign: "left" }}>
        <Typography className={classes.heading}>{heading}</Typography>
      </Box>
      <Grid container style={{ margin: "1rem 0 0 0" }}>
        <Grid item md={7} sm={8} xs={9}>
          <FormControl
            variant="outlined"
            fullWidth={true}
            data-testid={"" + heading + "-select"}
            required={!multiple}
          >
            {/* <InputLabel>{"Select"}</InputLabel> */}
            <Select
              id={heading}
              name={heading}
              onChange={(e) => {
                setProfile(e.target.value);
              }}
              value={profile}
              multiple={multiple}
              renderValue={(data) => {
                console.log(data);
                let d = "";
                if (multiple) {
                  data.map((i, index) => {
                    d += i.user.first_name + " " + i.user.last_name;
                    if (data.length != index + 1) {
                      d += ", ";
                    }
                  });
                } else {
                  d += data.user.first_name + " " + data.user.last_name;
                }
                return <div>{d}</div>;
              }}
            >
              {profiles.map((item, index) => (
                <MenuItem value={item} key={index}>
                  {`${item.user.first_name && item.user.first_name} ${
                    item.user.last_name && item.user.last_name
                  }`}{" "}
                  - <span>{item.user.email}</span>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={5} sm={4} xs={3}>
          <CreateProfile
            profileType={profileType}
            setProfile={setProfile}
            setProfiles={setProfiles}
            multiple={multiple}
            profile={profile}
            group={group}
          />
        </Grid>
      </Grid>
    </>
  );
}
