import { createSlice } from "@reduxjs/toolkit";
import instance from "api/globalaxios";
import { dispatch } from "d3";

export const initialState = {
  loading: false,
  hasErrors: false,
  questions: [],
};

const questionsSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {
    setProgress: (state) => {
      state.loading = true;
    },
    setSucessfull: (state, { payload }) => {
      state.questions = payload.results;
      state.loading = false;
      state.hasErrors = false;
    },
    setError: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

export const { setProgress, setSucessfull, setError } = questionsSlice.actions;
export default questionsSlice.reducer;

export function getQuestions(company_product, case_file_type) {
  console.log("In questisons");
  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instance({
        method: "get",
        url: "/question/",
        params: {
          company_product: company_product,
        case_file_type: case_file_type,
        },
      });

      console.log(response.data);
      dispatch(setSucessfull(response.data));
      return response.data;
    } catch (error) {
      console.log(error);
      // console.log(error.request.status);
      // console.log(error.message)
      // console.log(error.request.response)
      dispatch(setError);
    }
  };
}

export function getQuestionsAnonymous(company_product, case_file_type, email) {
  console.log("In questisons");
  return async (dispatch) => {
    dispatch(setProgress());
    instance({
      method: "get",
      url: "/anonymousquestions/",
      params: {
        company_product: company_product,
        case_file_type: case_file_type,
        email: email,
      },
    }).then(
      (response) => {
        console.log(response.data);
        dispatch(setSucessfull(response.data));
        return response.data;
      },
      (error) => {
        console.log(error);
        // console.log(error.request.status);
        // console.log(error.message)
        // console.log(error.request.response)
        dispatch(setError);
      }
    );
  };
}

export function getMuthootBranches(url, index, questions){
  return async (dispatch) => {
    dispatch(setProgress());
    instance({
      method: 'get',
      url: url,
    }).then((response)=>{
      console.log(response.data);
      const branches = response.data.branches.flat();
      const options = branches.map((branch) => `${branch.branch_name}, ${branch.district}`);
      const question = questions[index];
      question['type'] = 'dropdown';
      question['extra_args'] = JSON.stringify({options: options});
      questions[index] = question;
      dispatch(setSucessfull({results: questions}))
    })
    .catch((error)=>{
      console.log(error);
      dispatch(setError);
    })
  }
}
