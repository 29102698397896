import {
    Box,
    Button,
    CircularProgress,
    Grid,
    Paper,
    TextField,
    Typography,
    makeStyles,
  } from "@material-ui/core";
  import instance from "api/globalaxios";
  import React, { useEffect, useState } from "react";
  import { v4 as uuid } from "uuid";
  import UploadExcel from "./CreateMeeting/UploadExcel";
  import { DEV_WEBNYAY_URL, WEBNYAY_URL } from "app/constants";
  import { isDev } from "Routes/Routes";
  
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      padding: "2rem",
      [theme.breakpoints.down("sm")]: {
        padding: "1rem 0.5rem",
      },
    },
    heading: {
      fontSize: "24px",
      lineHeight: "28px",
      letterSpacing: "0.3px",
      color: "#252733",
      marginLeft: "20px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },
    username: {
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.2px",
      color: "#252733",
    },
    paper: {
      padding: "1rem",
      borderRadius: "5px",
    },
    text: {
      fontSize: "16px",
      lineHeight: "26px",
      color: "#2C2C2C",
      textTransform: "capitalize",
    },
    createMeetingButton: {
      backgroundColor: "white",
    },
    textField: {
      margin: '10px',
      fontSize: '16px',
      width: '250px',
      padding:'10px'
    }
  }));
  
  export default function CreateDirectMeeting({ profile, showExcelUpload }) {
    const classes = useStyles();
  
    const [loading, setLoading] = React.useState(false);
    const [errorType, setErrorType] = useState(null);
    const [isCopied, setIsCopied] = useState(false);
    const [isCopiedPadUrl, setIsCopiedPadUrl] = useState(false);
    const [meetingUrl, setMeetingUrl] = useState();
    const [padUrl, setPadUrl] = useState();
    const [disputeId, setDisputeId] = useState(null);
    const [padId, setPadId] = useState(null);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('')
  
    console.log(padId, padUrl, 'setPadIt')
    useEffect(() => {
      if (disputeId || padId) {
        if (window.location.hostname == "localhost") {
          setMeetingUrl(`http://${window.location.host}/meeting/${disputeId}`);
          setPadUrl(`http://3.110.221.244:9001/p/${padId}`)
        } else {
          setMeetingUrl(
            `https://${
              isDev ? DEV_WEBNYAY_URL : WEBNYAY_URL
            }/meeting/${disputeId}`
          );
          setPadUrl(`http://3.110.221.244:9001/p/${padId}`)
        }
      }
    }, [disputeId, padId]);
  
    async function createDyteMeeting() {
      let uniqueUserId =  localStorage.getItem('dyte_uuid') || uuid();
      let uniqueDisputeId = uuid();
      try {
        setLoading(true);
        setErrorType(null);
        setDisputeId(null);
        let response = await instance({
          method: "post",
          url: "/createdytemeetinganonymous",
          data: {
            disputeId: uniqueDisputeId,
            email: email,
            name: name,
            userid: uniqueUserId
          },
        });
        setLoading(false);
        localStorage.setItem('anonymousUserName', name);
        localStorage.setItem('dyte_uuid', uniqueUserId);
        setDisputeId(response?.data?.data.dispute_id);
        setPadId(response?.data?.pad_id);
      } catch (err) {
        const reason = err.response?.data?.reason;
        if (reason) {
          setErrorType(reason);
          setLoading(false);
          return;
        }
      }
    }
  
    return (
      <div className={classes.root}>
        <Grid container justify="flex-start" direction="column">
          <Grid item>
            <Grid
              container
              justify="space-between"
              direction="row"
              style={{ padding: "10px" }}
            >
              <Grid item>
                <Typography className={classes.heading}>
                  Create Meeting
                </Typography>
              </Grid>
            </Grid>
            <Box height="5rem"></Box>
            <Grid container direction="column" justify="center" alignItems="center" style={{ padding: "10px" }}>
                <Grid>
                  <TextField
                    id="name"
                    label="Name"
                    variant="outlined"
                    className={classes.textField}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                <Grid>
                  <TextField
                    id="email"
                    label="Email"
                    variant="outlined"
                    className={classes.textField}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
            </Grid>
            <Grid
              container
              style={{ marginLeft: "20px" }}
              justify="center"
              spacing={4}
            >
              <Grid item md={3} sm={3} xs={7}>
                <Button
                  variant="contained"
                  className={classes.createMeetingButton}
                  onClick={createDyteMeeting}
                  disabled={loading}
                >
                  {loading && (
                    <CircularProgress
                      style={{ width: 15, height: 15, marginRight: "0.5rem" }}
                    />
                  )}
                  <Typography
                    className={classes.text}
                    style={loading ? { color: "#878787" } : null}
                  >
                    Create Meeting
                  </Typography>
                </Button>
              </Grid>
              {(disputeId || errorType) && (
                <Grid item md={10} sm={10} xs={11}>
                  <Paper className={classes.paper}>
                    {errorType ? (
                      <Typography>{errorType}</Typography>
                    ) : (
                      <>
                        <Grid container justify="center">
                          <Grid item md={11} sm={11} xs={12}>
                            <TextField
                              variant="outlined"
                              size="small"
                              fullWidth
                              value={meetingUrl}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                          <Grid item md={1} sm={1} xs>
                            <Typography
                              style={{
                                fontSize: "1.2rem",
                                padding: "6px",
                                color: isCopied ? "green" : null,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                navigator.clipboard.writeText(meetingUrl);
                                setIsCopied(true);
                              }}
                            >
                              {isCopied ? "Copied!" : "Copy"}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container justify="center">
                          <Grid item md={11} sm={11} xs={12}>
                            <TextField
                              variant="outlined"
                              size="small"
                              fullWidth
                              value={padUrl}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                          <Grid item md={1} sm={1} xs>
                            <Typography
                              style={{
                                fontSize: "1.2rem",
                                padding: "6px",
                                color: isCopiedPadUrl ? "green" : null,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                navigator.clipboard.writeText(padUrl);
                                setIsCopiedPadUrl(true);
                              }}
                            >
                              {isCopiedPadUrl ? "Copied!" : "Copy"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Paper>
                </Grid>
              )}
  
              {/* {showExcelUpload && (
                <Grid item md={10} sm={10} xs={11}>
                  <UploadExcel />
                </Grid>
              )} */}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
  