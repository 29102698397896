import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { DateTimePicker } from "@material-ui/pickers";
import instance from "api/globalaxios";
import { isAfter, isBefore } from "date-fns";
import { Steps } from "intro.js-react";
import "intro.js/introjs.css";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  isArbitrationConference,
  isDev,
  isDifc,
  isDigipub,
  isHotStar,
  isMuthoot,
  isNSE,
  isRv,
  isSebi,
  isStar,
  isViacom,
  isWebnyay,
} from "Routes/Routes";
import { addCalenderEvent } from "slices/appointmentSlice";
import { gethost, getRealhost } from "slices/disputeSlice";
import { getAllGroups, setNoGroups } from "slices/groupSlice";
import { createGroupName } from "utils";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: "3rem auto",
    minHeight: "10rem",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    outline: "none",
    borderRadius: "0.4rem",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "80%",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "80%",
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "80%",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  greyColor: {
    color: theme.palette.common.grey1,
  },
  hoverButton: {
    background: "#3F51B5",
    color: "#FFFFFF",
    border: "1px solid transparent",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      color: "#3F51B5",
      border: "1px solid #3F51B5",
    },
  },
}));

export default function AddEventForm({
  groups,
  eventInfo,
  disputeId,
  handleAddEventClose,
  disputes,
  groupName,
  routeState,
  sources,
  profile,
  opendispute,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  // state handler for group change
  const [selectedGroup, setSelectedGroup] = React.useState(groupName);
  const handleGroupChange = (event) => {
    setSelectedGroup(event.target.value);
  };

  // start date and end date picker state handlers
  const [startDate, setStartDate] = React.useState(eventInfo.start);
  const [endDate, setEndDate] = React.useState(eventInfo.end);

  // errors after validating the time slots
  const [customError, setCustomError] = React.useState("");

  //error produced by datepicker
  const [autoError, setAutoError] = React.useState("");

  const isValid = autoError.length === 0 && customError.length === 0;

  // check whether the selected time falls between already booked meetings
  const validateTime = (time) => {
    let errorCount = 0;
    sources[0].events.forEach((event) => {
      if (
        isAfter(time, Date.parse(event.meeting.start)) &&
        isBefore(time, Date.parse(event.meeting.end))
      ) {
        errorCount++;
      }
    });
    if (errorCount > 0) {
      setCustomError("You already have an appointment");
    } else {
      setCustomError("");
    }
  };
  const [vcType, setVcType] = useState("meet_now");

  const [vc, setVc] = useState([
    { type: "zoom", name: "Zoom VC" },
    { type: "meet_now", name: "In-built VC" },
  ]);

  React.useEffect(() => {
    if (selectedGroup && (isDifc || isArbitrationConference)) {
      setVcType("meet_now");
      setVc([{ type: "meet_now", name: "In-built VC" }]);
    } else {
      setVc([
        { type: "zoom", name: "Zoom VC" },
        { type: "meet_now", name: "In-built VC" },
      ]);
    }
  }, [selectedGroup]);

  const [selectedDispute, setSelectedDispute] = React.useState(disputeId);
  const [ext_link, setExt_link] = React.useState("");
  const [loading, setLoading] = useState(false);

  const onTypeChange = (e) => {
    setVcType(e.target.value);
  };

  const handleDisputeChange = (event) => {
    setSelectedDispute(event.target.value);
    setSelectedGroup();
  };

  const createGroupNameHere = (groupName) => {
    let nameArray = groupName.split("_");
    let name = "";
    nameArray.slice(0, nameArray.length - 1).forEach((element) => {
      name = name + " " + element;
    });
    return name;
  };

  // onSubmit for schedule button
  const handleClickSchedule = async (e) => {
    let groupName = "external";
    if (!isHotStar && !isStar && !isMuthoot) groupName = createGroupNameHere(selectedGroup);
    let data = {
      dispute: selectedDispute,
      name: `${groupName} call`,
      group: groupName,
      start: startDate,
      end: endDate,
      ext_link: ext_link,
      host: getRealhost(),
      vc_type: vcType,
    };
    setLoading(true);
    dispatch(addCalenderEvent(data)).then(() => {
      handleAddEventClose();
      routeState && history.goBack();
    });
  };

  React.useEffect(() => {
    if (selectedDispute) {
      dispatch(getAllGroups(selectedDispute));
    } else {
      dispatch(setNoGroups());
    }
  }, [selectedDispute]);

  function addThirtyMinutes(date) {
    setStartDate(date);

    var copiedDate = new Date(date);
    copiedDate.setMinutes(copiedDate.getMinutes() + 30);
    copiedDate = new Date(copiedDate);

    setEndDate(copiedDate);
  }

  const location = useLocation();
  const user = useSelector((state) => state.user.user);
  // const [customid, setCustomid] = React.useState("")
  const [showGuide, setShowGuide] = useState(
    location.pathname == "/events" &&
      (user.id == 473 || user.id == 214) &&
      !isDev
  );

  const exit = (t) => {
    setShowGuide(false);
  };

  const start = () => {};

  const steps = [
    // {
    //   element: ".fc-custom1-button",
    //   intro: "Click here to schedule a meeting.",
    // },
    {
      element: ".calenderstep2",
      intro: "Change the date and time by clicking here.",
    },
  ];

  function gettypes() {
    return async (dispatch) => {
      try {
        const response = await instance.get(`/api/meeting/`);
        let v = [];
        v = response.data.VctypeChoices;
        setVc(v);
        setLoading(false);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  let customid = "";
  // if (profile.profile_type == 'sub_admin_tiac_rv' || (profile.profile_type == 'case_manager' && profile.is_tiac_profile == true)) {
  if (profile.is_tiac_profile == true) {
    opendispute.map((dispute) => {
      if (dispute.id == disputeId) {
        // setCustomid(dispute.tiac_custom_dispute_id)
        customid = dispute.tiac_custom_dispute_id;
      }
    });
  }

  // if (profile.is_tiac_profile == true)  {
  //   opendispute.map((dispute) => {

  //     if (dispute.id == disputeId) {

  //       // setCustomid(dispute.tiac_custom_dispute_id)
  //       customid = dispute.tiac_custom_dispute_id
  //     }

  //   })

  // }

  return (
    <Grid container className={classes.paper} justifyContent="center">
      <Grid item sm={12} xs={12} md={12}>
        {/* {opendispute.map((dispute) => {
          if (disputeId === dispute.id) {
            // setCustomid(dispute.id.tiac_custom_dispute_id)
          }
          else{
          }
        })} */}

        {/* // <div className={classes.paper}> */}
        <Steps
          enabled={showGuide}
          steps={steps}
          initialStep={0}
          onExit={exit}
          onStart={start}
          options={{
            hideNext: false,
            hidePrev: true,
            keyboardNavigation: true,
            exitOnEsc: true,
            exitOnOverlayClick: false,
            showBullets: false,
            showStepNumbers: false,
            disableInteraction: true,
          }}
        />
        <Typography variant="h5">Schedule an Event</Typography>
        <Box className={classes.container}>
          <Box height="3rem"></Box>
          <Box className="calenderstep2" style={{ width: "100%" }}>
            {(isHotStar || isStar || isMuthoot) ? (
              <DateTimePicker
                fullWidth
                variant="inline"
                label="Start time"
                inputVariant="outlined"
                value={startDate}
                onChange={(event) => addThirtyMinutes(event)}
                // disabled={eventInfo.startStr}
                minDate={new Date()}
                minDateMessage="Please select slots atleast 5 mins after current time"
                disablePast={true}
                strictCompareDates={true}
                onAccept={(info) => {
                  validateTime(info);
                }}
                onError={(error) => {
                  console.log(error);
                  setAutoError(error);
                }}
              />
            ) : (
              <DateTimePicker
                fullWidth
                variant="inline"
                label="Start time"
                inputVariant="outlined"
                value={startDate}
                onChange={(event) => addThirtyMinutes(event)}
                // disabled={eventInfo.startStr}
                minDate={new Date()}
                minDateMessage="Please select slots atleast 5 mins after current time"
                disablePast={true}
                strictCompareDates={true}
                onAccept={(info) => {
                  validateTime(info);
                }}
                onError={(error) => {
                  console.log(error);
                  setAutoError(error);
                }}
              />
            )}

            <Box height="1.5rem"></Box>
            {(isHotStar || isStar || isMuthoot) ? (
              <DateTimePicker
                fullWidth
                variant="inline"
                label="End time"
                inputVariant="outlined"
                value={endDate}
                onChange={setEndDate}
                // disabled={eventInfo.endStr}
                disablePast={true}
                minDate={startDate}
                minDateMessage="Date Should be after start date"
                strictCompareDates={true}
                onAccept={(info) => {
                  validateTime(info);
                }}
                onError={(error) => {
                  setAutoError(error);
                }}
              />
            ) : (
              <DateTimePicker
                fullWidth
                variant="inline"
                label="End time"
                inputVariant="outlined"
                value={endDate}
                onChange={setEndDate}
                // disabled={eventInfo.endStr}
                disablePast={true}
                minDate={startDate}
                minDateMessage="Date Should be after start date"
                strictCompareDates={true}
                onAccept={(info) => {
                  validateTime(info);
                }}
                onError={(error) => {
                  setAutoError(error);
                }}
              />
            )}
          </Box>
          <Box height="1.5rem" />
          {!disputeId ? (
            <DisputeSelect
              disputes={disputes}
              setSelectedDispute={setSelectedDispute}
              handleDisputeChange={handleDisputeChange}
              disabled={disputeId}
              profile={profile}
            />
          ) : profile.user.id === 236 ? (
            <TextField
              fullWidth
              disabled
              label="Grievance"
              defaultValue={disputeId}
              variant="outlined"
            />
          ) : (
            <TextField
              fullWidth
              disabled
              label={isRv ? "Case" : "Dispute"}
              defaultValue={customid ? customid : disputeId}
              variant="outlined"
            />
          )}
          <Box height="1.5rem" />

          {(isHotStar || isStar || isMuthoot) ? (
            <TextField
              fullWidth
              label="Meeting Link"
              variant="outlined"
              onChange={(e) => {
                setExt_link(e.target.value);
              }}
            />
          ) : groupName ? (
            <TextField
              fullWidth
              disabled
              label="Chat Group"
              defaultValue={createGroupName(groupName)}
              variant="outlined"
            />
          ) : (
            <GroupSelect
              groups={groups}
              selectedGroup={selectedGroup}
              handleGroupChange={handleGroupChange}
              disabled={groupName}
              profile={profile}
            />
          )}
          <Box height="1.5rem" />
          {((isRv && !isSebi) || isWebnyay) && (
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="group-select-label">Hearing Details</InputLabel>
              <Select
                id="Hearing Details"
                name="Hearing Details"
                value={vcType}
                onChange={onTypeChange}
                label="Hearing Details"
              >
                {vc.map((v, index) => (
                  <MenuItem key={v.type} value={v.type}>
                    {v.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <Box height="1.5rem" />
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickSchedule}
            disabled={(isHotStar || isStar) ? (!isValid || !selectedDispute || loading) : (!isValid || !selectedDispute || !selectedGroup || loading)}
            className={(isHotStar || isStar || isMuthoot) && classes.hoverButton}
          >
            Schedule
          </Button>
          <Box height="1rem" />
          {customError.length > 0 && (
            <Alert severity="error">{customError}</Alert>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

const GroupSelect = ({
  groups,
  selectedGroup,
  handleGroupChange,
  disabled,
  profile,
}) => {
  const createGroupNameHere = (groupName) => {
    let nameArray = groupName.split("_");
    let name = "";
    nameArray.slice(0, nameArray.length - 1).forEach((element) => {
      name = name + " " + element;
    });
    return name;
  };
  return (
    <FormControl variant="outlined" fullWidth disabled={disabled}>
      <InputLabel id="group-select-label">Chat Group</InputLabel>
      <Select
        labelId="group-select-label"
        id="group-select"
        value={selectedGroup}
        onChange={handleGroupChange}
        label="Chat Group"
      >
        {groups.map((group) => {
          if (profile.user.id === 236) {
            if (createGroupNameHere(group.name) != " negotiation") {
              return (
                <MenuItem
                  key={group._id}
                  value={group.name}
                  style={{ textTransform: "capitalize" }}
                >
                  {/* {group.name} */}
                  {createGroupName(group.name)}
                </MenuItem>
              );
            }
          } else {
            return (
              <MenuItem
                key={group._id}
                value={group.name}
                style={{ textTransform: "capitalize" }}
              >
                {/* {group.name} */}
                {createGroupName(group.name)}
              </MenuItem>
            );
          }
        })}
      </Select>
    </FormControl>
  );
};

const DisputeSelect = ({
  disputes,
  selectedDispute,
  handleDisputeChange,
  disabled,
  profile,
}) => {
  return (
    <FormControl variant="outlined" fullWidth disabled={disabled}>
      {(profile.profile_type === "vendor" &&
        (isWebnyay || isViacom || isDigipub)) ||
      (isHotStar || isStar || isMuthoot) ? (
        <InputLabel id="group-select-label">Grievance</InputLabel>
      ) : isNSE || isRv ? (
        <InputLabel id="group-select-label">Case</InputLabel>
      ) : (
        <InputLabel id="group-select-label">Dispute</InputLabel>
      )}
      <Select
        labelId="group-select-label"
        id="group-select"
        value={selectedDispute}
        onChange={handleDisputeChange}
        label="Dispute"
      >
        {disputes.map((dispute) => {
          let dispute_id = dispute.tiac_custom_dispute_id
            ? dispute.tiac_custom_dispute_id
            : dispute.id;
          return (
            <MenuItem key={dispute.id} value={dispute.id}>
              {dispute_id} : {dispute.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
